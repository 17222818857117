interface KubeadmConfigTemplateParams {
  name: string
  namespace: string
  flavor: string
  clusterName: string
  storage: string
  network: string
  storageSize?: number
}

export const createKaapiOpenStackMachineTemplateBody = ({
  name,
  namespace,
  flavor,
  clusterName,
  storage,
  network,
  storageSize = 100,
}: KubeadmConfigTemplateParams) => {
  return {
    apiVersion: 'infrastructure.cluster.x-k8s.io/v1beta1',
    kind: 'OpenStackMachineTemplate',
    metadata: {
      name,
      namespace,
    },
    spec: {
      template: {
        spec: {
          flavor,
          image: {
            filter: {
              name: 'demo-vm-image',
            },
          },
          sshKeyName: 'default',
          identityRef: {
            name: 'default-idref',
            cloudName: 'openstack',
          },
          ports: [
            {
              network: {
                id: network,
              },
            },
          ],
          // rootVolume: {
          //   type: storage,
          //   sizeGiB: storageSize,
          // },
        },
      },
    },
  }
}
