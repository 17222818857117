import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import React, { useState, useMemo } from 'react'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteKaapiMachines } from 'k8s/components/kaapi/machines/actions'
import useListAction from 'core/hooks/useListAction'
import { listKaapiClusters } from '../kaapi/clusters/actions'

const DeleteWorkerNodesDialog = ({ rows: [nodeGroup], onClose }) => {
  const [error, setError] = useState(null)
  const { update: deletingMachinesFn, updating: deletingMachines } = useUpdateAction(
    deleteKaapiMachines,
  )
  const { reload } = useListAction(listKaapiClusters)
  const deleteConfirmText = useMemo(() => getDeleteConfirmText([nodeGroup]), [nodeGroup])

  const handleSubmit = async () => {
    if (!nodeGroup?.kind) return
    try {
      await deletingMachinesFn({
        name: nodeGroup.metadata.name,
        namespace: nodeGroup.metadata.namespace,
      })
      reload(true)
      onClose(true)
    } catch (err) {
      console.error(err)
      setError({ title: 'Error Deleting Node Group', message: err?.message })
    }
  }

  return (
    <ConfirmationDialog
      text={deleteConfirmText}
      onConfirm={handleSubmit}
      onCancel={onClose}
      error={error}
      loading={deletingMachines}
      open
    />
  )
}

export default DeleteWorkerNodesDialog
