import yaml from 'js-yaml'

/**
 * Extracts the latest version for each minor version from the given config maps.
 */
export const extractSupportedK8sVersions = (configMaps) => {
  if (!Array.isArray(configMaps) || configMaps.length === 0) return []

  const latestVersions = new Map()

  for (const configMap of configMaps) {
    // Check if the config map is a cluster version config map
    const name = configMap.metadata?.name || ''
    if (!name.includes('cluster-version')) continue

    // Check if the config map has supportedVersion label and it is set to true
    const labels = configMap.metadata?.labels
    if (
      !Object.keys(labels).includes('kaapi.pf9.io/supportedVersion') ||
      !configMap.metadata?.labels['kaapi.pf9.io/supportedVersion']
    )
      continue

    const data = configMap.data || {}
    if (!data.supportedVersions) continue

    let versions
    try {
      versions = yaml.load(data.supportedVersions) || []
    } catch (error) {
      console.error('Error parsing supported versions in config map:', error)
      continue
    }

    // Update latest versions for each minor version
    for (const version of versions) {
      const [major, minor, patch] = version.split('.').map(Number)
      const minorKey = `${major}.${minor}`
      const currentVersion = latestVersions.get(minorKey)

      if (!currentVersion || compareVersions(version, currentVersion) > 0) {
        latestVersions.set(minorKey, version)
      }
    }
  }

  return Array.from(latestVersions.values())
    .sort(compareVersions)
    .reverse() // Return sorted in descending order
}

/**
 * Compares two semantic versions.
 * Returns:
 * - 1 if versionA > versionB
 * - -1 if versionA < versionB
 * - 0 if equal
 */
const compareVersions = (versionA, versionB) => {
  const [majorA, minorA, patchA] = versionA.split('.').map(Number)
  const [majorB, minorB, patchB] = versionB.split('.').map(Number)

  if (majorA !== majorB) return majorA - majorB
  if (minorA !== minorB) return minorA - minorB
  return patchA - patchB
}
