import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'

const { kaapi } = ApiClient.getInstance()

export const configMapActions = ActionsSet.make<DataKeys.ConfigMaps>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.ConfigMaps],
  cacheKey: DataKeys.ConfigMaps,
})

export const listKaapiConfigMaps = configMapActions.add(
  new ListAction<DataKeys.ConfigMaps, { namespace: string }>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Config Maps', { namespace })
    return kaapi.getConfigMaps({ namespace })
  }),
)
