import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'
import { hostedControlPlaneSelector } from '../hosted-control-plane/selectors'
import { openStackClustersSelector } from '../openstack-clusters/selectors'
import { kaapiMachineDeploymentsSelector } from '../machine-deployment/selectors'
import { kaapiMachinesSelector } from '../machines/selectors'
import { kaapiOpenStackMachineTemplateSelector } from '../openstack-machine-templates/selectors'

export const kaapiClustersSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  (kaapiClusters): any[] => {
    return kaapiClusters.map((cluster) => {
      return cluster
    })
  },
)

export const kaapiClustersOverviewSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiClusters>(DataKeys.KaapiClusters),
  hostedControlPlaneSelector,
  openStackClustersSelector,
  kaapiMachineDeploymentsSelector,
  kaapiMachinesSelector,
  kaapiOpenStackMachineTemplateSelector,
  (
    kaapiClusters,
    hostedControlPlanes,
    openStackClusters,
    kaapiMachineDeployments,
    kaapiMachines,
    kaapiOpenStackMachineTemplates,
  ): any[] => {
    return kaapiClusters.map((cluster) => {
      const hostedControlPlane = hostedControlPlanes?.find(
        (h) => h.metadata.name === cluster.spec.controlPlaneRef.name,
      )
      const openStackCluster = openStackClusters?.find(
        (h) => h.metadata.name === cluster.spec.infrastructureRef.name,
      )

      const machineDeployments = kaapiMachineDeployments
        ?.filter((md) => md.spec.clusterName === cluster.metadata.name)
        .map((md) => {
          // Find the openstack machine template for the machine deployment
          const openStackMachineTemplate = kaapiOpenStackMachineTemplates?.find((t) => {
            return t.metadata.name === md.spec.template.spec.infrastructureRef.name
          })

          return {
            uid: md?.metadata?.uid,
            ...md,
            spec: {
              ...md.spec,
              autoScaling: md?.metadata?.annotations?.[
                'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size'
              ]
                ? 'Enabled'
                : 'Disabled',
            },
            openStackMachineTemplate,
          }
        })

      const machines = kaapiMachines
        ?.filter((h) => h.spec.clusterName === cluster.metadata.name)
        .map((m) => {
          // Find the openstack machine template for the machine deployment
          const machineDeployment = machineDeployments?.find((md) => {
            return md.metadata.name === m.metadata.labels['cluster.x-k8s.io/deployment-name']
          })
          return {
            ...m,
            uid: m?.metadata?.uid,
            machineDeployment,
            cpuUsage: { current: 1, total: 2 },
            memoryUsage: { current: 1.5, total: 4 },
            storageUsage: { current: 5.5, total: 40 },
          }
        })

      return { ...cluster, hostedControlPlane, openStackCluster, machineDeployments, machines }
    })
  },
)
