import { isNilOrEmpty } from 'utils/fp'

interface KaapiMachineDeploymentParams {
  name: string
  namespace: string
  autoscaling?: { max: number }
  clusterName: string
  replicas: number
  kubeadmConfigTemplateRefName: string
  openStackMachineTemplateRefName: string
  k8sVersion: string
}

export const createKaapiMachineDeploymentBody = ({
  name,
  namespace,
  autoscaling,
  clusterName,
  replicas,
  kubeadmConfigTemplateRefName,
  openStackMachineTemplateRefName,
  k8sVersion,
}: KaapiMachineDeploymentParams) => {
  const enableAutoscaling = !isNilOrEmpty(autoscaling)
  const autoscalingAnnotations = enableAutoscaling
    ? {
        'cluster.x-k8s.io/cluster-api-autoscaler-node-group-max-size': autoscaling.max.toString(),
        'cluster.x-k8s.io/cluster-api-autoscaler-node-group-min-size': replicas.toString(),
      }
    : {}

  return {
    apiVersion: 'cluster.x-k8s.io/v1beta1',
    kind: 'MachineDeployment',
    metadata: {
      name,
      namespace,
      annotations: {
        ...autoscalingAnnotations,
      },
    },
    spec: {
      clusterName,
      replicas,
      template: {
        spec: {
          bootstrap: {
            configRef: {
              apiVersion: 'bootstrap.cluster.x-k8s.io/v1beta1',
              kind: 'KubeadmConfigTemplate',
              name: kubeadmConfigTemplateRefName,
            },
          },
          clusterName: clusterName,
          failureDomain: 'nova',
          infrastructureRef: {
            apiVersion: 'infrastructure.cluster.x-k8s.io/v1beta1',
            kind: 'OpenStackMachineTemplate',
            name: openStackMachineTemplateRefName,
          },
          version: k8sVersion,
        },
      },
    },
  }
}
