interface ClusterAddonParams {
  namespace: string
  clusterName: string
  addonType: string
  version: string
}

export const createKaapiClusterAddonBody = ({
  namespace,
  clusterName,
  addonType,
  version,
}: ClusterAddonParams) => {
  return {
    apiVersion: 'core.barista.platform9.io/v1alpha1',
    kind: 'ClusterAddon',
    metadata: {
      name: `${addonType}-addon-${clusterName}`,
      annotations: {
        'projectsveltos.io/template': 'true',
      },
      namespace,
    },
    spec: {
      clusterID: clusterName,
      type: addonType,
      version,
    },
  }
}
