import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const kaapiMachineDeploymentsSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiMachineDeployments>(DataKeys.KaapiMachineDeployments),
  (kaapiMachineDeployments): any[] => {
    return kaapiMachineDeployments.map((kaapiMachineDeployment) => {
      return kaapiMachineDeployment
    })
  },
)
