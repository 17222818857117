import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'

const { kaapi } = ApiClient.getInstance()

export const kaapiOpenstackMachineTemplateActions = ActionsSet.make<
  DataKeys.KaapiOpenStackMachineTemplates
>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiOpenStackMachineTemplates],
  cacheKey: DataKeys.KaapiOpenStackMachineTemplates,
})

export const listkaapiOpenstackMachineTemplates = kaapiOpenstackMachineTemplateActions.add(
  new ListAction<DataKeys.KaapiOpenStackMachineTemplates, { namespace: string }>(
    async ({ namespace }) => {
      Bugsnag.leaveBreadcrumb('Attempting to list Kaapi OpenStackMachineTemplate', { namespace })
      return kaapi.getOpenStackMachineTemplates({ namespace })
    },
  ),
)

export const createKaapiOpenStackMachineTemplate = kaapiOpenstackMachineTemplateActions.add(
  new CreateAction<DataKeys.KaapiOpenStackMachineTemplates, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi OpenStackMachineTemplate', {
        namespace,
        body,
      })
      const created = await kaapi.createOpenStackMachineTemplate(namespace, body)
      trackEvent('Create New Kaapi OpenStackMachineTemplate', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)
