interface HostedControlPlaneParams {
  name: string
  namespace: string
  version: string
  apiServerFlags?: string[]
  schedulerFlags?: string[]
  controllerManagerFlags?: string[]
}
export const createHostedControlPlaneBody = ({
  name,
  namespace,
  version,
  apiServerFlags = [],
  schedulerFlags = [],
  controllerManagerFlags = [],
}: HostedControlPlaneParams) => {
  return {
    apiVersion: 'controlplane.platform9.io/v1alpha1',
    kind: 'HostedControlPlane',
    metadata: {
      name,
      namespace,
    },
    spec: {
      apiServer: {
        extraArgs: [
          ...apiServerFlags,
          '--cloud-provider=external',
          '--advertise-address=10.96.0.40',
        ],
      },
      scheduler: {
        extraArgs: schedulerFlags,
      },
      controllerManager: {
        extraArgs: [...controllerManagerFlags, '--cloud-provider=external'],
      },
      addons: {
        coreDNS: {},
        konnectivity: {
          server: {
            port: 8132,
          },
        },
      },
      kubelet: {
        preferredAddressTypes: ['InternalIP', 'ExternalIP', 'Hostname'],
        cgroupfs: 'systemd',
      },
      version,
      hcpClass: 'default',
      hostname: `${name}.pcd-kaapi.platform9.horse`,
    },
  }
}
