interface KubeadmConfigTemplateParams {
  name: string
  namespace: string
}

export const createKaapiKubeadmConfigTemplateBody = ({
  name,
  namespace,
}: KubeadmConfigTemplateParams) => {
  return {
    apiVersion: 'bootstrap.cluster.x-k8s.io/v1beta1',
    kind: 'KubeadmConfigTemplate',
    metadata: {
      name,
      namespace,
    },
    spec: {
      template: {
        spec: {
          files: [
            {
              path: '/etc/kubernetes/patches/kubeletconfiguration0+strategic.json',
              owner: 'root:root',
              permissions: '0644',
              content:
                '{\n  "apiVersion": "kubelet.config.k8s.io/v1beta1",\n  "kind": "KubeletConfiguration",\n  "clusterDNS": ["10.96.0.10"],\n}\n  files:\n',
            },
            {
              path: '/etc/containerd/certs.d/docker.io/hosts.toml',
              content:
                'server = "https://docker.io"\n[host."https://dockermirror.platform9.io/"]\n  capabilities = ["pull", "resolve"]\n[host."https://registry-1.docker.io"]\n  capabilities = ["pull", "resolve"]\n',
            },
            {
              path: '/etc/containerd/config.toml',
              content:
                'version = 2\nroot = "/var/lib/containerd"\nstate = "/run/containerd"\nplugin_dir = ""\ndisabled_plugins = []\nrequired_plugins = []\noom_score = 0\n[plugins]\n  [plugins."io.containerd.grpc.v1.cri"]\n    sandbox_image = "registry.k8s.io/pause:3.6"\n    device_ownership_from_security_context = true\n    [plugins."io.containerd.grpc.v1.cri".registry]\n      config_path = "/etc/containerd/certs.d"\n  [plugins."io.containerd.grpc.v1.cri".containerd]\n    snapshotter = "overlayfs"\n  [plugins."io.containerd.grpc.v1.cri".containerd.runtimes]\n    [plugins."io.containerd.grpc.v1.cri".containerd.runtimes.runc]\n      runtime_type = "io.containerd.runc.v2"\n      [plugins."io.containerd.grpc.v1.cri".containerd.runtimes.runc.options]\n        SystemdCgroup = true\n',
            },
          ],
          joinConfiguration: {
            patches: {
              directory: '/etc/kubernetes/patches',
            },
            nodeRegistration: {
              kubeletExtraArgs: {
                'cloud-provider': 'external',
              },
              name: '{{ local_hostname }}',
            },
          },
        },
      },
    },
  }
}
