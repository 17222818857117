// libs
import { makeStyles } from '@material-ui/styles'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { prop } from 'ramda'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useReactRouter from 'use-react-router'

// Constants
import { UserPreferences } from 'app/constants'
// Components
import { RootState } from 'app/store'
import DocumentMeta from 'core/components/DocumentMeta'
import Text from 'core/elements/Text'
import { SessionState, sessionStoreKey } from 'core/session/sessionReducers'
import Theme from 'core/themes/model'
import { isDecco } from 'core/utils/helpers'
import { routes } from 'core/utils/routes'

import ApiClient from 'api-client/ApiClient'
import CreateButton from 'core/components/buttons/CreateButton'
import Progress from 'core/components/progress/Progress'
import Button from 'core/elements/button/Button'
import { HeaderPrimaryActionPortal } from 'core/elements/header/portals'
import useListAction from 'core/hooks/useListAction'
import downloadFile from 'core/utils/downloadFile'
import { emptyArr, emptyObj } from 'utils/fp'
import { decodeStr } from 'utils/misc'
import DeployCloudspaceModalForm from '../cluster/deployment/ClusterDeploymentForm'
import { listKaapiClusters, deleteKaapiCluster } from '../kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import DashboardConfigurationOverview from './dashboard-configuration-overview'
import DashboardPageHeader from './dashboard-page-header'
import { baseCards, isClusterDeleting } from './helpers'
import ResourceUtilizationCard from './resource-utilization-card'
import useUpdateAction from 'core/hooks/useUpdateAction'
import ConfirmationDialog from 'core/components/ConfirmationDialog'

const { kaapi } = ApiClient.getInstance()

const DashboardPage = () => {
  const classes = useStyles({})
  const { history } = useReactRouter()
  const { prefs, fetchUserDefaults, updateUserDefaults } = useScopedPreferences('defaults')
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')
  const { cards = emptyArr, isInitialized = false } = (prefs[UserPreferences.Dashboard] ||
    emptyObj) as { cards: Array<unknown>; isInitialized: boolean }
  const [showClusterDeploymentModal, setshowClusterDeploymentModal] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)

  // Fetch the list of clusters
  const { loading, reload } = useListAction(listKaapiClusters)
  const clusters = useSelector(kaapiClustersOverviewSelector)
  const cluster = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )

  // Delete cluster action
  const { update: deleteKaapiClusterFn, updating: deletingKaapiCluster } = useUpdateAction(
    deleteKaapiCluster,
  )

  // Reload the list of clusters when the cluster changes
  useEffect(() => {
    reload(true)
  }, [k8sPluginGlobalPerfs?.cluster])

  useEffect(() => {
    // Load user defaults from the preference store
    fetchUserDefaults(UserPreferences.Dashboard)
    fetchUserDefaults(UserPreferences.Cluster)
  }, [])

  const session = useSelector<RootState, SessionState>(prop(sessionStoreKey))
  const {
    userDetails: { displayName, role },
    features,
  } = session

  const isDeccoEnv = isDecco(features)
  useEffect(() => {
    if (isDeccoEnv && !isInitialized && !cards.length) {
      updateUserDefaults(UserPreferences.Dashboard, {
        isInitialized: true,
        cards: baseCards,
      })
    }
  }, [isDeccoEnv, isInitialized, cards])

  const handleDownload = async () => {
    const kubeconfig = await kaapi.downloadKubeconfig({ clusterName: cluster.metadata.name })

    downloadFile({
      filename: `${cluster.metadata.name}.yaml`,
      contents: decodeStr(kubeconfig),
    })
  }
  const deleteCluster = async () => {
    setShowDeleteDialog(false)
    await deleteKaapiClusterFn({ name: cluster?.metadata?.name })
    reload(true)
  }

  return (
    <Progress loading={loading}>
      <DocumentMeta title="Dashboard" />
      {clusters.length > 0 ? (
        <>
          <ConfirmationDialog
            open={showDeleteDialog}
            text={`Delete cluster - ${cluster?.metadata?.name} ?`}
            onCancel={() => setShowDeleteDialog(false)}
            onConfirm={deleteCluster}
            loading={deletingKaapiCluster}
          />
          <div className={classes.headerButtons}>
            <div className={classes.leftSidebuttons}>
              <Button icon="refresh" variant="secondary" onClick={reload}>
                Refresh
              </Button>
              <Button
                icon="trash"
                variant="secondary"
                onClick={() => setShowDeleteDialog(true)}
                style={{ marginLeft: '8px' }}
                disabled={isClusterDeleting(cluster)}
                disabledText="Deleting..."
                loading={deletingKaapiCluster}
              >
                Delete Cluster
              </Button>
            </div>
            <div>
              <Button icon="download" variant="secondary" onClick={handleDownload}>
                Download Kubeconfig
              </Button>
            </div>
          </div>
          <div className={classes.customGridContainer}>
            <div>
              <DashboardConfigurationOverview />
            </div>
            <div>
              <DashboardPageHeader />
              <div className={classes.headerSubText}>
                <Text variant="caption1" className={classes.headerText}>
                  RESOURCE UTILIZATION
                </Text>
              </div>
              <div className={classes.divider} />
              <ResourceUtilizationCard />
            </div>
          </div>
        </>
      ) : (
        <Text variant="body1">No clusters found</Text>
      )}
      {/* <DashboardPageHeader /> */}
      {/* <HeaderTitlePortal>
        <Text id="dashboard-title" variant="subtitle1">
          Welcome{displayName ? ` ${displayName}` : ''}!
        </Text>
      </HeaderTitlePortal> */}
      <HeaderPrimaryActionPortal>
        {/* <IconButton icon="pencil" info="Customize your dashboard" onClick={handleEditDashboard} /> */}
        <CreateButton onClick={() => setshowClusterDeploymentModal(true)}>
          {'Deploy New Cluster'}
        </CreateButton>
      </HeaderPrimaryActionPortal>

      {showClusterDeploymentModal && (
        <DeployCloudspaceModalForm onClose={() => setshowClusterDeploymentModal(false)} />
      )}
      {/* {kubeRegion && <CardMosaic cards={!isDeccoEnv ? baseCards : cards} role={role} />} */}
    </Progress>
  )
}

export default DashboardPage

const useStyles = makeStyles((theme: Theme) => ({
  customGridContainer: {
    display: 'grid',
    gridTemplateColumns: '370px 1fr',
    gridGap: '24px',
  },
  headerText: {
    color: theme.components.card.passiveText,
  },
  headerSubText: {
    paddingTop: '24px',
  },
  divider: {
    border: '0',
    height: '1px',
    margin: '16px 0px',
    background: '#e6e6ea',
  },
  headerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  leftSidebuttons: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
}))
