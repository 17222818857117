import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'

const { kaapi } = ApiClient.getInstance()

export const kaapiClusterAddonsActions = ActionsSet.make<DataKeys.KaapiClusterAddons>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiClusterAddons],
  cacheKey: DataKeys.KaapiClusterAddons,
})

export const listKaapiHostedControlPlanes = kaapiClusterAddonsActions.add(
  new ListAction<DataKeys.KaapiClusterAddons, { namespace: string }>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to list Kaapi KaapiClusterAddons', { namespace })
    return kaapi.getClusterAddons({ namespace })
  }),
)

export const createKaapiClusterAddon = kaapiClusterAddonsActions.add(
  new CreateAction<DataKeys.KaapiClusterAddons, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi ClusterAddon', {
        namespace,
        body,
      })
      const created = await kaapi.createClusterAddon(namespace, body)
      trackEvent('Create New Kaapi ClusterAddon', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)
