import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import ProgressBar from 'core/components/progress/ProgressBar'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'
import React from 'react'

interface Props {
  percent: number
}
const UsageProgressBar = ({ percent }) => {
  const classes = useStyles({ percent })
  return (
    <div>
      <ProgressBar
        variant="progress"
        percent={percent}
        showPercent={false}
        className={clsx(classes.progressBar, classes.progress)}
        color="success"
        width={'100%'}
      />
    </div>
  )
}
export default UsageProgressBar

const useStyles = makeStyles<Theme, Props>((theme) => ({
  progressBar: {
    height: '-webkit-fill-available',
  },
  progressBarWidth: {
    width: '100%',
  },
  progress: ({ percent }) => ({
    backgroundColor:
      percent < 40 ? colors.teal[200] : percent < 70 ? colors.beige[100] : colors.palette[700],
    border:
      percent < 40
        ? `1px solid ${colors.teal[100]}`
        : percent < 70
        ? `1px solid ${colors.yellow[500]}`
        : `1px solid ${colors.pink[800]}`,
  }),
}))
