import { makeStyles } from '@material-ui/styles'
import { compose } from 'app/utils/fp'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import { ValidatedFormProps } from 'pf9-ui-components/built/components/validatedForm/model'
import withFormContext from 'pf9-ui-components/built/components/validatedForm/withFormContext'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { FC } from 'react'
import NodePoolConfigCard from './NodePoolConfigCard'

interface ServerConfigurationFieldProps extends ValidatedFormProps {
  params: any
  getParamsUpdater: (key: string) => (value: unknown) => void
  vmFlavors: unknown[]
  storagePools: unknown[]
  networks: unknown[]
  nodePoolErrors: NodePoolErrors
}

export interface NodePoolErrors {
  [id: string]: { fieldId: string; message: string }
}

const ServerConfigurationField = ({
  params,
  getParamsUpdater,
  vmFlavors,
  storagePools,
  networks,
  nodePoolErrors = undefined,
}: ServerConfigurationFieldProps) => {
  const classes = useStyles()

  const handleAddNewNodePool = () => {
    const id = params.nodePools[params.nodePools.length - 1].id + 1
    getParamsUpdater('nodePools')([...params.nodePools, { id }])
  }

  const handleNodePoolChange = (id) => (newValues) => {
    const updatedNodePools = params.nodePools.map((nodePool) => {
      if (nodePool.id === id) {
        return { ...nodePool, ...newValues }
      }
      return nodePool
    })
    getParamsUpdater('nodePools')(updatedNodePools)
  }

  const handleDeleteNodePool = (id) => {
    const updatedNodePools = params.nodePools.filter((nodePool) => nodePool.id !== id)
    getParamsUpdater('nodePools')(updatedNodePools)
  }

  return (
    <div>
      {params.nodePools.map((nodePool) => {
        const id = nodePool?.id
        return (
          <NodePoolConfigCard
            key={id}
            nodePool={nodePool}
            onChange={handleNodePoolChange(id)}
            vmFlavors={vmFlavors}
            storagePools={storagePools}
            networks={networks}
            showDeleteButton={params.nodePools.length > 1}
            onDeleteNodePool={handleDeleteNodePool}
            errors={nodePoolErrors[id]}
          />
        )
      })}
      <div className={classes.addNodePoolButton}>
        <FontAwesomeIcon className={classes.plusIcon} onClick={handleAddNewNodePool} size="xl">
          plus-circle
        </FontAwesomeIcon>
        <Text variant="body2">Add Node Pool</Text>
      </div>
    </div>
  )
}

export default compose(withFormContext)(ServerConfigurationField) as FC<
  ServerConfigurationFieldProps
>

const useStyles = makeStyles<Theme>((theme) => ({
  addNodePoolButton: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, max-content)',
    margin: theme.spacing(4, 0),
    alignItems: 'center',
  },
  plusIcon: {
    color: theme.palette.blue[700],
    marginRight: theme.spacing(1),
    fontWeight: 900,
  },
}))
