import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { ErrorMessage } from 'core/components/validatedForm/ErrorMessage'
import Text from 'core/elements/Text'
import FontAwesomeIcon from 'pf9-ui-components/built/components/FontAwesomeIcon'
import QuantitySelector from 'pf9-ui-components/built/components/QuantitySelector'
import DropdownField from 'pf9-ui-components/built/components/validatedForm/DropdownField'
import ToggleSwitchField from 'pf9-ui-components/built/components/validatedForm/ToggleSwitchField'
import AsyncDropdown from 'pf9-ui-components/built/elements/dropdown/AsyncDropdown'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React, { useEffect, useMemo, useState } from 'react'
import VirtualMachineFlavorsTable from './VirtualMachineFlavorsTable'

interface NodePoolConfigCardProps {
  nodePool: any
  vmFlavors: any[]
  storagePools: any[]
  networks: any[]
  onChange: (value: Record<string, any>) => void
  onDeleteNodePool: (id: number) => void
  showDeleteButton?: boolean
  errors?: Errors
}

interface Errors {
  [fieldId: string]: string
}

export default function NodePoolConfigCard({
  nodePool,
  onChange,
  vmFlavors,
  storagePools,
  networks,
  showDeleteButton = false,
  onDeleteNodePool,
  errors = undefined,
}: NodePoolConfigCardProps) {
  const [collapseCard, setCollapseCard] = useState(false)
  const classes = useStyles({ collapseCard, showDeleteButton })
  const [autoscalingError, setAutoscalingError] = useState('')

  const storageOptions = useMemo(
    () =>
      storagePools
        .filter((storagePool) => !!storagePool.name)
        .map((storagePool) => ({
          label: storagePool.name,
          value: storagePool.name,
        })),
    [storagePools],
  )

  const networkOptions = useMemo(
    () =>
      networks.map((net) => ({
        label: net.name,
        value: net.id,
      })),
    [networks],
  )

  const handleVmChange = (value) => {
    onChange(value)
  }

  const handleAutoscaling = (enableAutoscaling) => {
    onChange(
      enableAutoscaling
        ? { autoscaling: { max: nodePool?.replicas || 1 } }
        : { autoscaling: undefined },
    )
  }

  const handleMaxNodesChange = (value) => {
    onChange({ autoscaling: { max: value } })
  }

  useEffect(() => {
    if (nodePool.autoscaling?.max < nodePool?.replicas) {
      setAutoscalingError(
        'The value must be greater than or equal to the selected amount of instance types.',
      )
    } else {
      setAutoscalingError('')
    }
  }, [nodePool.autoscaling?.max, nodePool.replicas])

  return (
    <div className={classes.nodePoolContainer}>
      <div className={classes.header} onClick={() => setCollapseCard(!collapseCard)}>
        <Text variant="subtitle2">{`#${nodePool?.id} Node Pool`}</Text>
        <FontAwesomeIcon solid size="xs" className={classes.toggleIcon}>
          chevron-up
        </FontAwesomeIcon>
      </div>
      {!collapseCard && (
        <div className={classes.content}>
          <div className={clsx(classes.vmSelection, classes.borderBottom)}>
            <VirtualMachineFlavorsTable
              data={vmFlavors}
              selectedVmFlavor={nodePool?.flavor}
              selectedVmQuantity={nodePool?.replicas}
              onChange={handleVmChange}
            />
            {errors && errors.flavor && <ErrorMessage>{errors.flavor}</ErrorMessage>}
          </div>
          <div className={clsx(classes.autoscalingFields, classes.borderBottom)}>
            <ToggleSwitchField
              id={`autoscaling-${nodePool.id}`}
              label="Automatically scale this Node Pool"
              onChange={handleAutoscaling}
            />
            {nodePool.autoscaling !== undefined && (
              <div className={classes.quantitySelector}>
                <Text variant="body2">Max Nodes</Text>
                <QuantitySelector
                  id={`maxNodes-${nodePool.id}`}
                  value={nodePool.autoscaling.max || 0}
                  onChange={handleMaxNodesChange}
                  min={nodePool.replicas}
                />
                {autoscalingError && <ErrorMessage>{autoscalingError}</ErrorMessage>}
                {errors && errors.autoscaling && <ErrorMessage>{errors.autoscaling}</ErrorMessage>}
              </div>
            )}
          </div>
          <div
            className={clsx(classes.dropdownFields, {
              [classes.borderBottom]: showDeleteButton,
            })}
          >
            <DropdownField
              id={`storage-${nodePool.id}`}
              label="Storage Pool"
              DropdownComponent={AsyncDropdown}
              items={storageOptions}
              value={nodePool.storage}
              onChange={(value) => onChange({ storage: value })}
              // required
              disabled
            />
            <DropdownField
              id={`network-${nodePool.id}`}
              label="Network / Subnet"
              DropdownComponent={AsyncDropdown}
              items={networkOptions}
              value={nodePool.network}
              onChange={(value) => onChange({ network: value })}
              required
            />
          </div>
          {showDeleteButton && (
            <div className={classes.deleteNodePoolButton}>
              <FontAwesomeIcon
                className={classes.minusIcon}
                onClick={() => onDeleteNodePool(nodePool?.id)}
                size="xl"
              >
                minus-circle
              </FontAwesomeIcon>
              <Text variant="body2">Delete Node Pool</Text>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

const useStyles = makeStyles<Theme, { collapseCard: boolean; showDeleteButton: boolean }>(
  (theme) => ({
    nodePoolContainer: {
      display: 'grid',
      border: `1px solid ${theme.components.card.border}`,
      borderRadius: '4px',
      marginBottom: theme.spacing(2),
      '& .accordionTopBar': {
        height: '48px',
      },
    },
    header: {
      backgroundColor: ({ collapseCard }) =>
        collapseCard
          ? `${theme.components.accordion.background}`
          : `${theme.components.accordion.activeBackground}`,
      borderTop: `1px solid  ${theme.components.accordion.border}`,
      color: `${theme?.components?.typography?.default}`,
      cursor: 'pointer',
      padding: '0 16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      border: 'none',
      transition: 'background-color 0.6s ease',
      height: '48px',
    },
    toggleIcon: {
      transform: ({ collapseCard }) => (collapseCard ? 'rotate(180deg)' : 'rotate(0deg)'),
      transition: 'transform 0.2s ease',
    },
    content: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridGap: theme.spacing(4),
      margin: theme.spacing(4, 3),
    },
    vmSelection: {
      display: 'grid',
      gridGap: theme.spacing(2),
    },
    borderBottom: {
      paddingBottom: theme.spacing(3),
      borderBottom: `1px solid ${theme.components.card.border}`,
    },
    autoscalingFields: {
      display: 'grid',
      gridGap: theme.spacing(2),
    },
    quantitySelector: {
      display: 'grid',
      gridTemplateColumns: 'max-content',
      gridGap: theme.spacing(2),
      marginTop: theme.spacing(),
    },
    dropdownFields: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gridGap: theme.spacing(3),
      paddingBottom: ({ showDeleteButton }) =>
        showDeleteButton ? theme.spacing(4) : theme.spacing(2),
    },
    deleteNodePoolButton: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, max-content)',
      padding: theme.spacing(1, 0, 3, 0),
      alignItems: 'center',
    },
    minusIcon: {
      color: theme.palette.blue[700],
      marginRight: theme.spacing(1),
      fontWeight: 900,
    },
  }),
)
