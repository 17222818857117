import ConfirmationDialog from 'core/components/ConfirmationDialog'
import { getDeleteConfirmText } from 'core/components/CRUDListContainer'
import React, { useState, useMemo } from 'react'
import useUpdateAction from 'core/hooks/useUpdateAction'
import { deleteKaapiMachineDeployments } from 'k8s/components/kaapi/machine-deployment/actions'
import useListAction from 'core/hooks/useListAction'
import { listKaapiClusters } from '../kaapi/clusters/actions'

const DeleteNodeGroupsDialog = ({ rows: [nodeGroup], onClose }) => {
  const [error, setError] = useState(null)

  const { reload } = useListAction(listKaapiClusters)

  const {
    update: deleteMachineDeploymentFn,
    updating: deletingMachineDeployment,
  } = useUpdateAction(deleteKaapiMachineDeployments)

  const deleteConfirmText = useMemo(() => getDeleteConfirmText([nodeGroup]), [nodeGroup])

  const handleSubmit = async () => {
    if (!nodeGroup?.kind) return
    try {
      await deleteMachineDeploymentFn({
        name: nodeGroup.metadata.name,
        namespace: nodeGroup.metadata.namespace,
      })

      reload(true)
      onClose(true)
    } catch (err) {
      console.error(err)
      setError({ title: 'Error Deleting Node Group', message: err?.message })
    }
  }

  return (
    <ConfirmationDialog
      text={deleteConfirmText}
      onConfirm={handleSubmit}
      onCancel={onClose}
      error={error}
      loading={deletingMachineDeployment}
      open
    />
  )
}

export default DeleteNodeGroupsDialog
