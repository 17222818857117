import { AppPlugins } from 'app/constants'
import Plugin from 'core/plugins/plugin'

// import AlarmsListPage from 'k8s/components/alarms/AlarmsListPage'

import DashboardPage from './components/dashboard/dashboard-page'

import ComingSoonPage from './ComingSoonPage'
import { kubernetesLogo } from './logo'
import HomePage from './pages/HomePage'
import CapacityAndHealthDashboard from './components/capacity-and-health/capacity-and-health-dashboard'

const kubernetesPlugin = new Plugin(
  AppPlugins.Kubernetes,
  'Kubernetes Clusters',
  '/ui/kubernetes',
  kubernetesLogo,
  'Kubernetes clusters for modern workloads',
)

// kubernetesPlugin.registerRoutes([
//   {
//     name: 'Dashboard',
//     link: { path: '/dashboard/overview', exact: true, default: true },
//     component: DashboardPage,
//   },
//   {
//     name: 'Alarms',
//     link: { path: '/dashboard/alarms', exact: true },
//     component: AlarmOverviewPage,
//   },
//   {
//     name: 'Edit Dashboard',
//     link: { path: '/dashboard/overview/edit', exact: true },
//     component: EditDashboardPage,
//   },
//   {
//     name: 'Notifications',
//     link: { path: '/notifications/:notificationType/:id?', exact: true },
//     component: NotificationsPage,
//   },
//   {
//     name: 'App Catalog',
//     link: { path: '/apps/app-catalog/(deploy)?/:repository?/:name?', exact: true },
//     requiredFeatures: isDecco,
//     component: AppCatalogPage,
//   },
//   {
//     name: 'Deployed Apps',
//     link: {
//       path: '/apps/deployed-apps',
//       exact: true,
//     },
//     requiredFeatures: isDecco,
//     component: DeployedAppsPage,
//   },
//   {
//     name: 'Deployed App Details',
//     link: {
//       path: '/apps/:clusterId/:namespace/deployed-apps/(edit)?/:repository/:chart/:name/:tab?',
//       exact: true,
//     },
//     requiredFeatures: isDecco,
//     component: DeployedAppDetailsPage,
//   },
//   {
//     name: 'Repositories',
//     link: { path: '/apps/repositories/(add||edit)?/:id?', exact: true },
//     requiredFeatures: isDecco,
//     component: RepositoriesListPage,
//   },
//   {
//     name: 'Pods',
//     link: { path: '/workloads/pods/(add)?', exact: true },
//     component: PodsListPage,
//   },
//   {
//     name: 'Pod Details',
//     link: { path: '/workloads/clusters/:clusterId/pods/:id/:tab?', exact: true },
//     component: PodOverviewPage,
//   },
//   {
//     name: 'Pod Container Details',
//     link: {
//       path: '/workloads/clusters/:clusterId/pods/:id/containers/:containerName',
//       exact: true,
//     },
//     component: PodContainerDetailsPage,
//   },
//   {
//     name: 'Deployments',
//     link: { path: '/workloads/deployments/(add)?', exact: true },
//     component: DeploymentsListPage,
//   },
//   {
//     name: 'Deployment Details',
//     link: { path: '/workloads/clusters/:clusterId/deployments/:id/:tab?', exact: true },
//     component: DeploymentOverviewPage,
//   },
//   {
//     name: 'Services',
//     link: { path: '/workloads/services/(add)?', exact: true },
//     component: ServicesListPage,
//   },
//   {
//     name: 'Service Details',
//     link: { path: '/workloads/clusters/:clusterId/services/:id/:tab?', exact: true },
//     component: ServiceOverviewPage,
//   },
//   {
//     name: 'Stateful Sets',
//     link: { path: '/workloads/stateful-sets/(add)?', exact: true },
//     component: StatefulSetsListPage,
//   },
//   {
//     name: 'Stateful Set Details',
//     link: { path: '/workloads/clusters/:clusterId/stateful-sets/:id/:tab', exact: true },
//     component: StatefulSetOverviewPage,
//   },
//   {
//     name: 'Stateful Set Container Details',
//     link: {
//       path: '/workloads/clusters/:clusterId/stateful-sets/:id/containers/:containerName',
//       exact: true,
//     },
//     component: StatefulSetsContainerDetailsPage,
//   },
//   {
//     name: 'Replica Sets',
//     link: { path: '/workloads/replica-sets/(add)?', exact: true },
//     component: ReplicaSetsListPage,
//   },
//   {
//     name: 'Replica Set Details',
//     link: { path: '/workloads/clusters/:clusterId/replica-sets/:id/:tab', exact: true },
//     component: ReplicaSetOverviewPage,
//   },
//   {
//     name: 'Replica Set Container Details',
//     link: {
//       path: '/workloads/clusters/:clusterId/replica-sets/:id/containers/:containerName',
//       exact: true,
//     },
//     component: ReplicaSetsContainerDetailsPage,
//   },
//   {
//     name: 'Daemon Sets',
//     link: { path: '/workloads/daemon-sets/(add)?', exact: true },
//     component: DaemonSetsListPage,
//   },
//   {
//     name: 'Daemon Set Container Details',
//     link: {
//       path: '/workloads/clusters/:clusterId/daemon-sets/:id/containers/:containerName',
//       exact: true,
//     },
//     component: DaemonSetContainerDetailsPage,
//   },
//   {
//     name: 'Daemon Set Details',
//     link: {
//       path: '/workloads/clusters/:clusterId/daemon-sets/:id/:tab?',
//       exact: true,
//     },
//     component: DaemonSetOverviewPage,
//   },
//   {
//     name: 'Cronjobs',
//     link: { path: '/workloads/cron-jobs/(add)?', exact: true },
//     component: CronjobsListPage,
//   },
//   {
//     name: 'Jobs',
//     link: { path: '/workloads/jobs/(add)?', exact: true },
//     component: CronjobsListPage,
//   },
//   {
//     name: 'Cronjob Details',
//     link: { path: '/workloads/clusters/:clusterId/cron-jobs/:id/:tab?', exact: true },
//     component: CronjobOverviewPage,
//   },
//   {
//     name: 'CronJob Container Details',
//     link: {
//       path: '/workloads/clusters/:clusterId/cron-jobs/:id/containers/:containerName',
//       exact: true,
//     },
//     component: CronjobContainerDetailsPage,
//   },
//   {
//     name: 'Job Details',
//     link: { path: '/workloads/clusters/:clusterId/jobs/:id/:tab?', exact: true },
//     component: JobOverviewPage,
//   },
//   {
//     name: 'Job Container Details',
//     link: {
//       path: '/workloads/clusters/:clusterId/jobs/:id/containers/:containerName',
//       exact: true,
//     },
//     component: JobContainerDetailsPage,
//   },
//   {
//     name: 'Namespaces',
//     link: { path: '/workloads/namespaces/(add)?', exact: true },
//     component: NamespacesListPage,
//   },
//   {
//     name: 'CSI Drivers',
//     link: { path: '/storage/csi-drivers', exact: true },
//     component: CSIDriversListPage,
//   },
//   {
//     name: 'Persistent Volumes',
//     link: { path: '/storage/persistent-volumes/(add)?', exact: true },
//     component: PersistentVolumesListPage,
//   },
//   {
//     name: 'Persistent Volume Details',
//     link: { path: '/storage/clusters/:clusterId/persistent-volumes/:id/:tab?', exact: true },
//     component: PersistentVolumeDetailsPage,
//   },
//   {
//     name: 'Persistent Volume Claims',
//     link: { path: '/storage/persistent-volume-claims', exact: true },
//     component: PersistentVolumeClaimsListPage,
//   },
//   {
//     name: 'Persistent Volume Claim Details',
//     link: { path: '/storage/clusters/:clusterId/persistent-volume-claims/:id/:tab?', exact: true },
//     component: PersistentVolumeClaimDetailsPage,
//   },
//   {
//     name: 'Storage Classes',
//     link: { path: '/storage/storage-classes/(add)?', exact: true },
//     component: StorageClassListPage,
//   },
//   {
//     name: 'Storage Providers',
//     link: { path: '/storage/storage-providers', exact: true },
//     component: StorageProvidersListPage,
//   },
//   // TODO why not put storage details as a page inside of cluster details?
//   {
//     name: 'Storage Class Details',
//     link: { path: '/storage/clusters/:clusterId/storage-classes/:id/:tab?', exact: true },
//     component: StorageDetailsPage,
//   },
//   {
//     name: 'API Access API Services',
//     link: { path: '/api-access/api-services', exact: true },
//     component: ApiServicesPage,
//   },
//   {
//     name: 'API Access Kubeconfig',
//     link: { path: '/api-access/kubeconfig', exact: true },
//     component: KubeConfigListPage,
//   },
//   {
//     name: 'API Access Terraform',
//     link: { path: '/api-access/terraform', exact: true },
//     component: TerraformListPage,
//   },
//   {
//     name: 'Guided Onboarding',
//     link: { path: '/onboarding', exact: true },
//     component: OnboardingPage,
//   },
//   {
//     name: 'RBAC Roles',
//     requiredRoles: 'admin',
//     link: { path: '/rbac/roles/(add||edit)?/:id?/(cluster)?/:clusterId?', exact: true },
//     component: RolesListPage,
//   },
//   {
//     name: 'RBAC Role Bindings',
//     requiredRoles: 'admin',
//     link: { path: '/rbac/role-bindings/(add||edit)?/:id?/(cluster)?/:clusterId?', exact: true },
//     component: RoleBindingsListPage,
//   },
//   {
//     name: 'RBAC Cluster Roles',
//     requiredRoles: 'admin',
//     link: { path: '/rbac/cluster-roles/(add||edit)?/:id?/(cluster)?/:clusterId?', exact: true },
//     component: ClusterRolesListPage,
//   },
//   {
//     name: 'RBAC Cluster Role Bindings',
//     requiredRoles: 'admin',
//     link: {
//       path: '/rbac/cluster-role-bindings/(add||edit)?/:id?/(cluster)?/:clusterId?',
//       exact: true,
//     },
//     component: ClusterRoleBindingsListPage,
//   },
//   {
//     name: 'Monitoring Alarms',
//     link: { path: '/monitoring/alarms', exact: true },
//     component: AlarmChartListPage,
//   },
//   {
//     name: 'Monitoring Rules',
//     link: { path: '/monitoring/rules', exact: true },
//     component: RulesListPage,
//   },
//   {
//     name: 'Help',
//     link: { path: '/help/:tab?', exact: true },
//     component: HelpPage,
//   },
//   {
//     name: 'Networking Overview',
//     link: { path: '/networking/overview', exact: true },
//     component: NetworkingOverviewPage,
//   },
//   {
//     name: 'Network',
//     link: { path: '/networking/network-attachment-definitions/(add)?', exact: true },
//     component: NetworkAttachmentDefinitionsListPage,
//   },
//   {
//     name: 'Network Details',
//     link: {
//       path: '/networking/clusters/:clusterId/network-attachment-definitions/:id/:tab?',
//       exact: true,
//     },
//     component: NetworkAttachmentDefinitionsDetailsPage,
//   },
//   {
//     name: 'Network Plugins',
//     link: { path: '/networking/plugins/(add)?', exact: true },
//     component: NetworkPluginsListPage,
//   },
//   {
//     name: 'Network Plugins Details',
//     link: { path: '/networking/clusters/:clusterId/plugins/:id/:tab?', exact: true },
//     component: NetworkPluginsDetailsPage,
//   },
//   {
//     name: 'Config Maps',
//     link: { path: '/configuration/config-maps/(add)?', exact: true },
//     component: ConfigMapsListPage,
//   },
//   {
//     name: 'Config Maps Details',
//     link: { path: '/configuration/clusters/:clusterId/config-maps/:id/:tab?', exact: true },
//     component: ConfigMapsDetailsPage,
//   },
//   {
//     name: 'Secrets',
//     link: { path: '/configuration/secrets/(add)?', exact: true },
//     component: SecretsListPage,
//   },
//   {
//     name: 'Secrets Details',
//     link: { path: '/configuration/clusters/:clusterId/secrets/:id/:tab?', exact: true },
//     component: SecretsDetailsPage,
//   },
//   {
//     name: 'Host Configurations',
//     link: { path: 'networking/host-configurations/(add)?', exact: true },
//     component: HostConfigurationsListPage,
//   },
//   {
//     name: 'Host Configuration Details',
//     link: { path: '/networking/clusters/:clusterId/host-configurations/:id/:tab?', exact: true },
//     component: HostConfigurationDetailsPage,
//   },
// ])

kubernetesPlugin.registerRoutes([
  {
    name: 'Home',
    link: { path: '/', exact: true, default: true },
    component: HomePage,
  },
  {
    name: 'Dashboard',
    link: { path: '/clusters/:cluster/manage/overview', exact: true },
    component: DashboardPage,
  },
  {
    name: 'Capacity & Health',
    link: { path: '/clusters/:cluster/manage/capacity-and-health', exact: true },
    component: CapacityAndHealthDashboard,
  },
  {
    name: 'Alerts',
    link: { path: '/clusters/:cluster/manage/alerts', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Kubernetes Configuration',
    link: { path: '/clusters/:cluster/manage/kubernetes-configuration', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Kubernetes Add-ons',
    link: { path: '/clusters/:cluster/manage/kuberntes-addons', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Load Balancers',
    link: { path: '/clusters/:cluster/deploy/load-balancers', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Virtual Machines',
    link: { path: '/clusters/:cluster/deploy/virtual-machines', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Apps',
    link: { path: '/clusters/:cluster/deploy/apps', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'API',
    link: { path: '/api', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Docs',
    link: { path: '/docs', exact: true },
    component: ComingSoonPage,
  },
  {
    name: 'Support',
    link: { path: '/support', exact: true },
    component: ComingSoonPage,
  },
])

// These nav items are in active development but not shown in production.
const navItems = [
  {
    name: 'Manage',
    link: { path: '/clusters/:cluster/manage/overview' },
    icon: 'cloud-word',
    nestedLinks: [
      { name: 'Overview', link: { path: '/clusters/:cluster/manage/overview' } },
      {
        name: 'Capacity & Health',
        link: { path: '/clusters/:cluster/manage/capacity-and-health' },
      },
      {
        name: 'Alerts',
        link: { path: '/clusters/:cluster/manage/alerts' },
      },
      {
        name: 'Kubernetes Configuration',
        link: { path: '/clusters/:cluster/manage/kubernetes-configuration' },
      },
      { name: 'Kubernetes Add-ons', link: { path: '/clusters/:cluster/manage/kuberntes-addons' } },
    ],
  },
  {
    name: 'Deploy',
    link: {
      path: '/clusters/:cluster/deploy/load-balancers',
      definition: '/clusters/:cluster/deploy/load-balancers',
    },
    icon: 'rocket',
    nestedLinks: [
      {
        name: 'Load Balancers',
        link: {
          path: '/clusters/:cluster/deploy/load-balancers',
          definition: '/clusters/:cluster/deploy/load-balancers',
        },
      },
      {
        name: 'Virtual Machines',
        link: {
          path: '/clusters/:cluster/deploy/virtual-machines',
          definition: '/clusters/:cluster/deploy/virtual-machines',
        },
      },
      {
        name: 'Apps',
        link: {
          path: '/clusters/:cluster/deploy/apps',
          definition: '/clusters/:cluster/deploy/apps',
        },
      },
    ],
  },
  {
    name: 'Resources',
    link: {
      path: '/api',
      definition: '/configuration/(clusters)?/:id?/:page?/(edit)?',
    },
    icon: 'box-open',
    nestedLinks: [
      {
        name: 'API',
        link: {
          path: '/api',
          definition: '/api',
        },
      },
      {
        name: 'Docs',
        link: {
          path: '/docs',
          definition: '/docs',
        },
      },
      {
        name: 'Support',
        link: {
          path: '/support',
          definition: '/support',
        },
      },
    ],
  },
]

kubernetesPlugin.registerNavItems(navItems)

export default kubernetesPlugin
