import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import { dummyData } from '../clusters/dummyData'

const { kaapi } = ApiClient.getInstance()

export const kaapiOpenStackClusterActions = ActionsSet.make<DataKeys.KaapiOpenStackClusters>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiOpenStackClusters],
  cacheKey: DataKeys.KaapiOpenStackClusters,
})

export const listKaapiOpenStackClusters = kaapiOpenStackClusterActions.add(
  new ListAction<DataKeys.KaapiOpenStackClusters, { namespace: string }>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to list Kaapi OpenStackClusters', { namespace })
    return kaapi.getOpenStackClusters({ namespace })
  }),
)

export const createKaapiOpenStackCluster = kaapiOpenStackClusterActions.add(
  new CreateAction<DataKeys.KaapiOpenStackClusters, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi OpenStackCluster', { namespace, body })
      const created = await kaapi.createOpenStackCluster(namespace, body)
      trackEvent('Create New Kaapi OpenStackClusterAction', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)
