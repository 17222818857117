import { makeStyles } from '@material-ui/styles'
import Text, { TextVariant } from 'pf9-ui-components/built/elements/Text'
import Tooltip from 'pf9-ui-components/built/elements/tooltip'
import { topMiddle } from 'pf9-ui-components/built/elements/menu/defaults'
import Theme from 'core/themes/model'
import React from 'react'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import clsx from 'clsx'
import colors from 'core/themes/base/colors'

interface StyleProps {
  variant: StyledBulletTextProps['variant']
  badgeColor: StyledBulletTextProps['badgeColor']
  badgeColorShade: StyledBulletTextProps['badgeColorShade']
  badgeBgColor: StyledBulletTextProps['badgeBgColor']
  badgeBgColorShade: StyledBulletTextProps['badgeBgColorShade']
}
export type BadgeVariant =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'unknown'
  | 'danger'
  | 'error'
interface StyledBulletTextProps {
  color: BadgeVariant
  label: string | any
  textVariant?: TextVariant
  rightBorder?: boolean
  padding?: boolean
  showTooltip?: boolean
  tooltipLabel?: string
  margin?: boolean
  id?: string
  icon?: string
  isDisabled?: boolean
  variant?: BadgeVariant
  badgeColor?: string
  badgeColorShade?: number
  badgeBgColor?: string
  badgeBgColorShade?: number
}
const defaultVariant = 'default'
export const StyledIconText = ({
  color,
  label,
  textVariant = 'body2',
  rightBorder = false,
  padding = false,
  showTooltip = false,
  tooltipLabel = label,
  margin = true,
  id,
  icon,
  isDisabled = false,
  variant = defaultVariant,
  badgeColor,
  badgeColorShade,
  badgeBgColor,
  badgeBgColorShade,
}: StyledBulletTextProps) => {
  const classes = useStyles({
    variant,
    badgeColor: badgeColor,
    badgeColorShade: badgeColorShade,
    badgeBgColor: badgeBgColor,
    badgeBgColorShade: badgeBgColorShade,
  })
  return (
    <>
      {showTooltip ? (
        <Tooltip align={topMiddle.align} message={tooltipLabel}>
          <div
            className={classes.bulletTextWrapper}
            style={{
              padding: padding ? '0 8px 0 0' : '0',
              margin: margin ? '8px 0' : '0',
            }}
          >
            <FontAwesomeIcon size="md">{icon}</FontAwesomeIcon>
            <Text
              className={classes.customText}
              id={id}
              variant={textVariant}
              nonce={undefined}
              onResize={undefined}
              onResizeCapture={undefined}
            >
              {label}
            </Text>
          </div>
        </Tooltip>
      ) : (
        <div
          className={classes.bulletTextWrapper}
          style={{
            padding: padding ? '0 8px 0 0' : '0',
            margin: margin ? '8px 0' : '0',
          }}
        >
          <div className={clsx(classes.badge)}>
            <FontAwesomeIcon size="lg">{icon}</FontAwesomeIcon>
          </div>
          <Text
            className={classes.customText}
            id={id}
            variant={textVariant}
            nonce={undefined}
            onResize={undefined}
            onResizeCapture={undefined}
          >
            {label}
          </Text>
          {rightBorder && <div className={classes.separator}></div>}
        </div>
      )}
    </>
  )
}

export default StyledIconText

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  badge: {
    display: 'flex', // Ensures proper alignment of content
    alignItems: 'center',
    justifyContent: 'center',
    width: '32px', // Define a fixed width
    height: '32px', // Make height equal to width for a circle
    borderRadius: '50%', // Fully rounded corners for a circle
    padding: 0, // No padding to maintain circular shape
    color: ({ badgeColor, badgeColorShade }) => colors[badgeColor][badgeColorShade], // Dynamic color,
    backgroundColor: ({ badgeBgColor, badgeBgColorShade }) =>
      colors[badgeBgColor][badgeBgColorShade],
    whiteSpace: 'nowrap', // To prevent text wrapping
    border: ({ variant }) => `1px solid ${theme.components.badge?.[variant]?.background}`,
    '&:hover': {
      border: ({ variant }) => `1px solid ${theme.components.badge?.[variant]?.color}`,
    },
  },
  bulletTextWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing(1)}px 0`,
    '&:last-child': {
      border: 'none',
    },
  },
  bullet: {
    display: 'inline-block',
    width: 8,
    height: 8,
    borderRadius: 100,
    marginRight: theme.spacing(1),
  },
  customText: {
    color: 'inherit',
    paddingLeft: theme.spacing(1),
  },
  customTextDisabled: {
    color: theme.palette.grey[300],
  },
  borderColor: {
    borderRight: `1px solid ${theme.palette.grey.main}`,
    paddingRight: '8px',
  },
  separator: {
    width: '1px',
    height: '24px',
    backgroundColor: theme.components.card.border,
    margin: '8px',
  },
}))
