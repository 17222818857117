import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import ListAction from 'core/actions/ListAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import DeleteAction from 'core/actions/DeleteAction'

const { kaapi } = ApiClient.getInstance()

export const kaapiClusterActions = ActionsSet.make<DataKeys.KaapiClusters>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiClusters],
  cacheKey: DataKeys.KaapiClusters,
})

export const listKaapiClusters = kaapiClusterActions.add(
  new ListAction<DataKeys.KaapiClusters>(async ({ namespace }) => {
    Bugsnag.leaveBreadcrumb('Attempting to get Kaapi Clusters ', { namespace })
    return kaapi.getKaapiClusters({ namespace })
  })
    .addDependency(DataKeys.KaapiHostedControlPlanes)
    .addDependency(DataKeys.KaapiOpenStackClusters)
    .addDependency(DataKeys.KaapiMachineDeployments)
    .addDependency(DataKeys.KaapiOpenStackMachineTemplates)
    .addDependency(DataKeys.KaapiMachines),
)

export const deleteKaapiCluster = kaapiClusterActions.add(
  new DeleteAction<DataKeys.KaapiClusters, { namespace: string; name: string }>(
    async ({ namespace, name }) => {
      Bugsnag.leaveBreadcrumb('Attempting to delete Kaapi cluster', { namespace, name })
      await kaapi.deleteKaapiCluster({ namespace, name })
    },
  ),
)

export const createKaapiCluster = kaapiClusterActions.add(
  new CreateAction<DataKeys.KaapiClusters, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi Cluster', { namespace, body })
      const created = await kaapi.createCluster(namespace, body)
      trackEvent('Create New Kaapi Cluster', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)
