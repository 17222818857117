interface OpenStackClusterBodyParams {
  name: string
  namespace: string
  network: { id: string; network_id: string; name: string; subnet_id: string }
}

export const createKaapiOpenStackClusterBody = ({
  name,
  namespace,
  network,
}: OpenStackClusterBodyParams) => {
  return {
    apiVersion: 'infrastructure.cluster.x-k8s.io/v1beta1',
    kind: 'OpenStackCluster',
    metadata: {
      name,
      namespace,
    },
    spec: {
      apiServerLoadBalancer: {
        enabled: false,
      },
      network: { id: network?.id },
      subnets: network?.['subnets']?.map((subnet) => ({
        id: subnet,
      })),
      controlPlaneAvailabilityZones: ['nova'],
      disableAPIServerFloatingIP: true,
      disableExternalNetwork: true,
      apiServerFixedIP: '',
      identityRef: {
        name: 'default-idref',
        cloudName: 'openstack',
      },
      managedSecurityGroups: {
        allowAllInClusterTraffic: true,
        allNodesSecurityGroupRules: [
          {
            remoteManagedGroups: ['worker'],
            direction: 'ingress',
            etherType: 'IPv4',
            name: 'BGP',
            portRangeMin: 179,
            portRangeMax: 179,
            protocol: 'tcp',
            description: 'Allow BGP among workers',
          },
          {
            remoteManagedGroups: ['worker'],
            direction: 'ingress',
            etherType: 'IPv4',
            name: 'IP-in-IP',
            protocol: '4',
            description: 'Allow IP-in-IP among workers',
          },
          {
            remoteManagedGroups: ['worker'],
            direction: 'ingress',
            etherType: 'IPv4',
            name: 'SSH',
            portRangeMin: 22,
            portRangeMax: 22,
            protocol: 'tcp',
            description: 'Allow SSH',
          },
        ],
      },
    },
  }
}
