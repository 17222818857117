import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const openStackClustersSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiOpenStackClusters>(DataKeys.KaapiOpenStackClusters),
  (kaapiOpenStackClusters): any[] => {
    return kaapiOpenStackClusters.map((kaapiOpenStackCluster) => {
      return kaapiOpenStackCluster
    })
  },
)
