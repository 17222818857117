import { DashBoardStatusCardTypes } from './card-templates'

import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'
import { omit } from 'ramda'
import { BadgeVariant } from './styled-icon-text'
import { getNodeGroupStatuses } from 'app/plugins/infrastructure/components/clusters/capi/helpers'
import { isNilOrEmpty } from 'utils/fp'
import { Phase } from 'app/plugins/infrastructure/components/clusters/capi/machine-deployment/model'
import { CapiClusterPhases } from 'app/plugins/infrastructure/components/clusters/capi/model'

export const useDashboardPageStyles = makeStyles<Theme>((theme) => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',

    minWidth: 'max-content',
    maxWidth: 'max-content',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 6,
    backgroundColor: theme.palette.grey['000'],
    padding: theme.spacing(2, 4),
    border: `solid 1px ${theme.palette.grey[300]}`,
    minHeight: 75,
  },
}))

export const filterReportsWithUserRole = (reports, role) => {
  const mappedReports = reports.map((report) => {
    // No permissions property means no restrictions
    if (!report?.permissions) {
      return report
    }
    // remove the add action when not permitted to
    return report.permissions.includes(role) ? report : { ...report, addRoute: '' }
  })
  const filteredReports = mappedReports.filter((report) => {
    if (!report.overallPermissions) {
      return report
    }
    return report.overallPermissions.includes(role)
  })
  return filteredReports.map(omit(['permissions', 'overallPermissions']))
}

export const baseCards = [
  DashBoardStatusCardTypes.Pod,
  DashBoardStatusCardTypes.Deployment,
  DashBoardStatusCardTypes.Service,
]

export const allReportCardsDefaultOrder = [
  DashBoardStatusCardTypes.Pod,
  DashBoardStatusCardTypes.Deployment,
  DashBoardStatusCardTypes.Service,
  DashBoardStatusCardTypes.User,
  DashBoardStatusCardTypes.Tenant,
]

export interface NodeGroupStatus {
  label: number
  icon: string
  variant: BadgeVariant
  badgeColor: string
  badgeColorShade: number
  badgeBgColor: string
  badgeBgColorShade: number
}

export const DEFAULT_NODE_GROUP_STATUS = {
  success: {
    label: 0,
    icon: 'circle-check',
    variant: 'success' as BadgeVariant,
    badgeColor: 'teal',
    badgeColorShade: 100,
    badgeBgColor: 'teal',
    badgeBgColorShade: 50,
  },
  error: {
    label: 0,
    icon: 'triangle-exclamation',
    variant: 'error' as BadgeVariant,
    badgeColor: 'pink',
    badgeColorShade: 800,
    badgeBgColor: 'peach',
    badgeBgColorShade: 50,
  },
  warning: {
    label: 0,
    icon: 'triangle-exclamation',
    variant: 'warning' as BadgeVariant,
    badgeColor: 'red',
    badgeColorShade: 400,
    badgeBgColor: 'peach',
    badgeBgColorShade: 100,
  },
  unknown: {
    label: 0,
    icon: 'circle-question',
    variant: 'unknown' as BadgeVariant,
    badgeColor: 'grey',
    badgeColorShade: 500,
    badgeBgColor: 'grey',
    badgeBgColorShade: 100,
  },
}

// Generate Node Group status based on the machine deployments
export const generateNodeGroupsStatus = (machineDeployments): Array<NodeGroupStatus> => {
  const nodeGroupsStatus = JSON.parse(JSON.stringify(DEFAULT_NODE_GROUP_STATUS))

  machineDeployments?.forEach((machineDeployment) => {
    switch (machineDeployment?.status?.phase) {
      case 'Ready':
      case 'Running':
        nodeGroupsStatus.success.label++
        break
      case 'Failed':
      case 'Error':
        nodeGroupsStatus.error.label++
        break
      case 'ScalingUp':
      case 'ScalingDown':
        nodeGroupsStatus.warning.label++
        break
      default:
        nodeGroupsStatus.unknown.label++
        break
    }
  })

  return Object.values(nodeGroupsStatus).filter(
    (status: NodeGroupStatus) => status.label > 0,
  ) as Array<NodeGroupStatus>
}

export const generateWorkerNodesStatus = (machines): Array<NodeGroupStatus> => {
  const workerNodesStatus = JSON.parse(JSON.stringify(DEFAULT_NODE_GROUP_STATUS))

  machines?.forEach((machines) => {
    switch (machines?.status?.phase) {
      case 'Provisioned':
      case 'Running':
        workerNodesStatus.success.label++
        break
      case 'Provisioning':
      case 'SaclingUp':
      case 'SaclingDown':
        workerNodesStatus.warning.label++
        break
      case 'Failed':
      case 'Error':
        workerNodesStatus.error.label++
        break
      default:
        workerNodesStatus.unknown.label++
        break
    }
  })

  return Object.values(workerNodesStatus).filter(
    (status: NodeGroupStatus) => status.label > 0,
  ) as Array<NodeGroupStatus>
}

export const isClusterDeleting = (cluster) => {
  return cluster?.status?.phase?.toLowerCase() === 'deleting'
}

export const isKaapiClusterHealthy = (cluster) => {
  const nodeGroups = cluster?.machineDeployments
  if (isNilOrEmpty(nodeGroups)) return false

  const statuses = getNodeGroupStatuses(nodeGroups)
  const allNodesRunning = statuses[Phase.Running]?.count === nodeGroups.length
  return (
    cluster?.status?.phase === CapiClusterPhases.Provisioned &&
    cluster?.hostedControlPlane?.status?.ready &&
    allNodesRunning
  )
}
