import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const kaapiOpenStackMachineTemplateSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiOpenStackMachineTemplates>(DataKeys.KaapiOpenStackMachineTemplates),
  (kaapiOpenStackMachineTemplates): any[] => {
    return kaapiOpenStackMachineTemplates.map((kaapiOpenStackMachineTemplate) => {
      return kaapiOpenStackMachineTemplate
    })
  },
)
