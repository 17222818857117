import ApiClient from 'api-client/ApiClient'
import ActionsSet from 'core/actions/ActionsSet'
import CreateAction from 'core/actions/CreateAction'
import DataKeys, { entityNamesByKey } from 'k8s/DataKeys'
import Bugsnag from 'utils/bugsnag'
import { pathStr } from 'utils/fp'
import { trackEvent } from 'utils/tracking'
import { dummyData } from '../clusters/dummyData'
import ListAction from 'core/actions/ListAction'

const { kaapi } = ApiClient.getInstance()

export const kaapiHostedControlPlaneActions = ActionsSet.make<DataKeys.KaapiHostedControlPlanes>({
  uniqueIdentifier: 'metadata.uid',
  entityName: entityNamesByKey[DataKeys.KaapiHostedControlPlanes],
  cacheKey: DataKeys.KaapiHostedControlPlanes,
})

export const listKaapiHostedControlPlanes = kaapiHostedControlPlaneActions.add(
  new ListAction<DataKeys.KaapiHostedControlPlanes, { namespace: string }>(
    async ({ namespace }) => {
      Bugsnag.leaveBreadcrumb('Attempting to list Kaapi HostedControlPlanes', { namespace })
      return kaapi.getHostedControlPlanes({ namespace })
    },
  ),
)

export const createKaapiHostedControlPlane = kaapiHostedControlPlaneActions.add(
  new CreateAction<DataKeys.KaapiHostedControlPlanes, { namespace: string; body: any }>(
    async ({ namespace, body }) => {
      Bugsnag.leaveBreadcrumb('Attempting to create Kaapi HostedControlPlanes', { namespace, body })
      const created = await kaapi.createHostedControlPlane(namespace, body)
      trackEvent('Create New Kaapi HostedControlPlane', {
        namespace,
        name: pathStr('metadata.name', created),
      })
      return created
    },
  ),
)
