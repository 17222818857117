import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const hostedControlPlaneSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiHostedControlPlanes>(DataKeys.KaapiHostedControlPlanes),
  (hostedControlPlane): any[] => {
    return hostedControlPlane.map((hostedControlPlane) => {
      return hostedControlPlane
    })
  },
)
