import { makeStyles } from '@material-ui/styles'

import { ICON_STATUS_COLOR_MAP, CLUSTER_STATUS_COLOR_MAP, ICON_STATUS_MAP } from 'app/constants'
import clsx from 'clsx'
import FontAwesomeIcon from 'core/components/FontAwesomeIcon'
import Text from 'core/elements/Text'
import Badge from 'core/elements/badge'
import colors from 'core/themes/base/colors'
import Theme from 'core/themes/model'
import React, { useState } from 'react'

import { listKaapiClusters } from '../kaapi/clusters/actions'
import useListAction from 'core/hooks/useListAction'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { useSelector } from 'react-redux'
import { path } from 'ramda'

import { isClusterDeleting, isKaapiClusterHealthy } from './helpers'

const DashboardConfigurationOverview = () => {
  const classes = useStyles({})
  const overviewBody = [
    {
      label: 'CONTROL PLANE',
      key: 'spec.controlPlaneRef.kind',
      defaultValue: 'Hosted Control Plane',
      badge: '',
    },
    {
      label: 'CONTROL PLANE CLASS',
      key: 'hostedControlPlane.spec.hcpClass',
      defaultValue: 'Highly Available - 3 Masters',
      badge: '',
    },
    {
      label: 'API ENDPOINT',
      key: 'hostedControlPlane.spec.hostname',
      defaultValue: 'cluster.x-k8s.io/v1beta1',
      badge: '',
    },
    {
      label: 'INFRASTRUCTURE PROVIDER',
      // key: 'spec.infrastructureRef.kind',
      key: 'spec.infrastructureRef.dummy',
      labelsTooltipDescription: 'INFRASTRUCTURE PROVIDER',
      defaultValue: 'PCD Virtualization',
      badge: '',
    },
    {
      label: 'K8S VERSION',
      key: 'hostedControlPlane.spec.version',
      defaultValue: 'v1.29.5',
      badge: '',
    },
    {
      label: 'Management Cluster Namespace',
      key: 'metadata.namespace',
      defaultValue: 'default',
      badge: '',
    },
  ]

  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')

  // Fetch the list of clusters and filter the one that is currently selected
  const { loading } = useListAction(listKaapiClusters)
  const clusters = useSelector(kaapiClustersOverviewSelector)
  const cluster = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )

  if (!cluster) {
    return null
  }

  const isClusterHealthy = isKaapiClusterHealthy(cluster)

  return (
    <div className={classes.cardContainer}>
      <div className={classes.cardContent}>
        <div className={classes.badge}>
          <FontAwesomeIcon light size="lg" className="fa-regular">
            server
          </FontAwesomeIcon>
        </div>
        <Text variant="caption1" className={clsx(classes.headerText, classes.headerSubText)}>
          CONFIGURATION OVERVIEW
        </Text>
      </div>
      <div className={clsx(classes.cardContent, classes.content)}>
        <Text variant="subtitle1">{cluster?.metadata?.name}</Text>
        <div className={classes.headerSubText}>
          <Badge
            data-testid={'overview-tab-emp-status'}
            variant={isClusterHealthy && !isClusterDeleting(cluster) ? 'success' : 'error'}
            text={
              isClusterDeleting(cluster) ? 'Deleting' : isClusterHealthy ? 'Healthy' : 'Unhealthy'
            }
            ellipsisAt={20}
            // tooltipBody={cluster?.status?.phase}
          />
        </div>
      </div>
      <div className={classes.cardsRow}>
        {overviewBody.map(({ key, label, defaultValue, badge }, index) => {
          const value = path(key.split('.'), cluster) || defaultValue

          return (
            <div key={`key-${index}`}>
              <Text variant="body2" className={clsx(classes.headerText, classes.customText)}>
                {label}
              </Text>
              <div className={classes.cardContent}>
                <Text variant="caption1">
                  {label === 'API ENDPOINT' ? `https://${value}` : value}
                </Text>
                {badge && (
                  <div className={classes.headerSubText}>
                    <Badge
                      data-testid={'overview-tab-emp-status'}
                      variant={ICON_STATUS_COLOR_MAP[badge] || 'unknown'}
                      text={ICON_STATUS_MAP[badge]}
                      ellipsisAt={20}
                      tooltipBody={badge?.length > 20 && ICON_STATUS_MAP[badge]}
                    />
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DashboardConfigurationOverview

const useStyles = makeStyles((theme: Theme) => ({
  cardsRow: {
    display: 'grid',
    marginTop: '8px',
    gridColumnGap: '32px',
    gridRowGap: '24px',
    alignItems: 'center',
    paddingTop: '48px',
  },
  cardContainer: {
    backgroundColor: theme.components.card.background,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRadius: '4px 4px 4px 4px',
    padding: '24px',
  },
  headerText: {
    color: theme.components.card.passiveText,
  },
  customText: {
    fontSize: '12px',
    paddingBottom: '8px',
  },
  headerSubText: {
    paddingLeft: '8px',
  },
  badge: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    backgroundColor: colors.blue[900],
    borderRadius: '4px 4px 4px 4px',
    padding: '3px',
    '& i': {
      color: 'white',
    },
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center',
  },
  content: {
    paddingTop: '16px',
  },
}))
