import { makeStyles } from '@material-ui/styles'

import Text from 'core/elements/Text'
import Theme from 'core/themes/model'
import { routes } from 'core/utils/routes'
import React, { useEffect, useMemo, useState } from 'react'
import useReactRouter from 'use-react-router'
import StyledIconText, { BadgeVariant } from '../dashboard/styled-icon-text'
import useListAction from 'core/hooks/useListAction'
import { listKaapiClusters } from '../kaapi/clusters/actions'
import { kaapiClustersOverviewSelector } from '../kaapi/clusters/selectors'
import useScopedPreferences from 'core/session/useScopedPreferences'
import { useSelector } from 'react-redux'
import {
  DEFAULT_NODE_GROUP_STATUS,
  generateNodeGroupsStatus,
  generateWorkerNodesStatus,
  isClusterDeleting,
  isKaapiClusterHealthy,
  NodeGroupStatus,
} from '../dashboard/helpers'

const CapacityAndHealthPageHeader = () => {
  const classes = useStyles({})
  const { history, match } = useReactRouter()
  const { cluster } = match.params
  const routeToCreateEmp = () => history.push(routes.manage.capacityAndHealth.path({ cluster }))
  const { prefs: k8sPluginGlobalPerfs } = useScopedPreferences('k8sPluginGlobalParams')
  const [clusterHealth, setClusterHealth] = useState<NodeGroupStatus>(
    DEFAULT_NODE_GROUP_STATUS.success,
  )
  const [controlPlaneStatus, setControlPlaneStatus] = useState<NodeGroupStatus>(
    DEFAULT_NODE_GROUP_STATUS.success,
  )

  const { loading: loadingKaapiClusters, reload: reloadKaapiClusters } = useListAction(
    listKaapiClusters,
  )
  const clusters = useSelector(kaapiClustersOverviewSelector)
  const clusterData = clusters?.find(
    (cluster) => cluster.metadata.name === k8sPluginGlobalPerfs?.cluster,
  )
  const machineDeployments = clusterData?.machineDeployments || []
  const machines = clusterData?.machines || []

  // Map the machine deployments status to get the node groups status
  const nodeGroupsStatus = useMemo(() => {
    return generateNodeGroupsStatus(machineDeployments)
  }, [machineDeployments])

  const workerNodesStatus = useMemo(() => {
    return generateWorkerNodesStatus(machines)
  }, [machines])

  // Check if the cluster is healthy
  const isClusterHealthy = isKaapiClusterHealthy(clusterData)
  useEffect(() => {
    if (isClusterHealthy) {
      setClusterHealth(DEFAULT_NODE_GROUP_STATUS.success)
    } else {
      setClusterHealth(DEFAULT_NODE_GROUP_STATUS.error)
    }
  }, [isClusterHealthy])

  // Control Plane Status
  const isControlPlaneReady = clusterData?.hostedControlPlane?.status?.ready
  useEffect(() => {
    if (isControlPlaneReady) {
      setControlPlaneStatus(DEFAULT_NODE_GROUP_STATUS.success)
    } else {
      setControlPlaneStatus(DEFAULT_NODE_GROUP_STATUS.error)
    }
  }, [isControlPlaneReady])

  // If cluster data is not available, return null
  if (!clusterData) {
    return null
  }

  return (
    <>
      <div className={classes.cardsRow}>
        <div className={classes.cardContainer}>
          <div className={classes.cardContent}>
            <Text variant="subtitle2">Overall Health</Text>

            <Text variant="body2" className={classes.content}>
              <StyledIconText
                color={'unknown'}
                icon={clusterHealth?.icon}
                badgeColorShade={clusterHealth?.badgeColorShade}
                badgeBgColorShade={clusterHealth?.badgeBgColorShade}
                badgeBgColor={clusterHealth?.badgeBgColor}
                badgeColor={clusterHealth?.badgeColor}
                variant={isClusterHealthy && !isClusterDeleting(cluster) ? 'success' : 'error'}
                label={
                  isClusterDeleting(cluster)
                    ? 'Deleting'
                    : isClusterHealthy
                    ? 'Healthy'
                    : 'Unhealthy'
                }
              />
            </Text>
          </div>
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.cardContent}>
            <Text variant="subtitle2">Control Plane</Text>

            <Text variant="body2" className={classes.content}>
              <StyledIconText
                color={'unknown'}
                label={
                  isControlPlaneReady
                    ? 'Ready'
                    : clusterData?.hostedControlPlane?.status?.phase || 'Not Ready'
                }
                icon={controlPlaneStatus?.icon}
                variant={controlPlaneStatus?.variant}
                badgeColorShade={controlPlaneStatus?.badgeColorShade}
                badgeBgColorShade={controlPlaneStatus?.badgeBgColorShade}
                badgeBgColor={controlPlaneStatus?.badgeBgColor}
                badgeColor={controlPlaneStatus?.badgeColor}
              />
            </Text>
          </div>
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.cardContent}>
            <div className={classes.rightContent}>
              <Text variant="subtitle2">Node Groups ({machineDeployments.length})</Text>
            </div>
            <Text variant="body2" className={classes.content}>
              {nodeGroupsStatus.length ? (
                nodeGroupsStatus?.map((item, index) => (
                  <StyledIconText
                    key={index}
                    color={'unknown'}
                    label={item?.label || ''}
                    icon={item?.icon}
                    variant={item?.variant}
                    badgeColorShade={item?.badgeColorShade}
                    badgeBgColorShade={item?.badgeBgColorShade}
                    badgeBgColor={item?.badgeBgColor}
                    badgeColor={item?.badgeColor}
                    rightBorder={index !== workerNodesStatus?.length - 1}
                    padding={true}
                  />
                ))
              ) : (
                <Text variant="body2" style={{ padding: '16px 0' }}>
                  No Node Groups
                </Text>
              )}
            </Text>
          </div>
        </div>
        <div className={classes.cardContainer}>
          <div className={classes.cardContent}>
            <div className={classes.rightContent}>
              <Text variant="subtitle2">Worker Nodes ({machines.length})</Text>
            </div>
            <Text variant="body2" className={classes.content}>
              {workerNodesStatus.length ? (
                workerNodesStatus?.map((item, index) => (
                  <StyledIconText
                    key={index}
                    color={'unknown'}
                    label={item?.label || ''}
                    icon={item?.icon}
                    variant={item?.variant}
                    badgeColorShade={item?.badgeColorShade}
                    badgeBgColorShade={item?.badgeBgColorShade}
                    badgeBgColor={item?.badgeBgColor}
                    badgeColor={item?.badgeColor}
                    rightBorder={index !== workerNodesStatus?.length - 1}
                    padding={true}
                  />
                ))
              ) : (
                <Text variant="body2" style={{ padding: '16px 0' }}>
                  No Worker Nodes
                </Text>
              )}
            </Text>
          </div>
        </div>
      </div>
    </>
  )
}

export default CapacityAndHealthPageHeader

const useStyles = makeStyles((theme: Theme) => ({
  cardsRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
  cardContainer: {
    backgroundColor: theme.components.card.background,
    border: `1px solid ${theme.palette.grey[200]}`,
    borderRight: '1px solid #e6e6ea',
    minHeight: '6.25rem',
    '&:nth-child(4n+1)': {
      // First card in each row
      borderRadius: '4px 0px 0px 4px',
    },
    '&:nth-child(4n)': {
      // Last card in each row
      borderRadius: '0px 4px 4px 0px',
    },
  },
  cardContent: {
    display: 'grid',
    alignContent: 'center',
    marginLeft: '24px',
    height: '100%',
    textAlign: 'start',
  },
  content: {
    marginTop: '0.5rem',
    display: 'flex',
  },
  rightContent: {
    marginTop: '0.5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center', // Ensures vertical alignment
  },
  icon: {
    padding: '4px 8px',
  },
  seeAll: {
    display: 'flex',
    alignItems: 'center',
    gap: 0, // Ensures no space between "See All" and the icon
    paddingRight: '8px',
  },
}))
