import { useEffect, useState } from 'react'
import { getTimeRangeData } from 'core/utils/helpers'
import AsyncDropdown from 'core/elements/dropdown/AsyncDropdown'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Theme from 'core/themes/model'

export interface TimeRangePicklist {
  label: string
  value: string
}
interface Props {
  onChange: (
    selectedTimeRange: string,
    startTimeEpoch: number,
    endTimeEpoch: number,
    steps: string,
    interval?: number,
  ) => void
  defaultTimeRange?: string
  currentTimeRange?: string
  isModalView?: boolean
}
const items = [
  { label: 'Last One Hour', value: 'last_one_hour' },
  { label: 'Last 3 Hours', value: 'last_3_hours' },
  { label: 'Last 6 Hours', value: 'last_6_hours' },
  { label: 'Last 12 Hours', value: 'last_12_hours' },
  { label: 'Last 24 Hours', value: 'last_24_hours' },
  { label: 'Last Week', value: 'last_week' },
  { label: 'Last Month', value: 'last_month' },
  { label: 'Last 7 Days', value: 'last_7_days' },
  { label: 'Last 30 Days', value: 'last_30_days' },
  { label: 'Last 60 Days', value: 'last_60_days' },
]
export function TimePicklist({ onChange, currentTimeRange = 'last_one_hour', isModalView }: Props) {
  const classes = useStyles()
  const handleOnChange = (selectedTimeRange) => {
    const { startTimeEpoch, endTimeEpoch, steps, interval } = getTimeRangeData(
      selectedTimeRange,
      isModalView,
    )
    onChange(selectedTimeRange, startTimeEpoch, endTimeEpoch, steps, interval)
  }

  useEffect(() => {
    if (!isModalView && currentTimeRange !== '') {
      handleOnChange(currentTimeRange || 'last_one_hour')
    }
  }, [isModalView, currentTimeRange])
  return (
    <AsyncDropdown
      compact
      onChange={handleOnChange}
      items={items}
      value={currentTimeRange || 'last_one_hour'}
      className={classes.customDropdown}
    />
  )
}
export default TimePicklist

const useStyles = makeStyles((theme: Theme) => ({
  customDropdown: {
    '& .placeholder': {
      fontWeight: 400,
    },
    '& .dropdownMenu': {
      maxHeight: 250,
    },
  },
}))
