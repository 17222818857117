import { makeStyles } from '@material-ui/styles'
import Text from 'core/elements/Text'
import SelectableCard from 'pf9-ui-components/built/components/SelectableCard'
import Radio from 'pf9-ui-components/built/elements/input/Radio'
import Theme from 'pf9-ui-components/built/theme-manager/themes/model'
import React from 'react'

export default function ControlPlaneCard({
  id,
  onClick,
  active,
  title,
  description,
  comingSoon = false,
}) {
  const classes = useStyles()

  return (
    <div className={classes.cardWrapper}>
      {comingSoon && (
        <Text variant="caption1" className={classes.comingSoonFlag}>
          Coming soon!
        </Text>
      )}
      <SelectableCard
        id={id}
        onClick={onClick}
        active={active}
        className={classes.controlPlaneCard}
        disabled={comingSoon}
        withCustomBody
      >
        <div className={classes.cardBody}>
          <Radio checked={active} />
          <div>
            <Text variant="caption1">{title}</Text>
            <Text variant="body2">{description}</Text>
          </div>
        </div>
      </SelectableCard>
    </div>
  )
}

const useStyles = makeStyles<Theme>((theme) => ({
  cardWrapper: {
    position: 'relative',
    width: '346px',
    height: '80px',
  },
  controlPlaneCard: {
    width: '100%',
    height: '100%',
  },
  cardBody: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridGap: theme.spacing(1),
    padding: '16px',
  },
  comingSoonFlag: {
    position: 'absolute',
    top: 15,
    right: -10,
    backgroundColor: theme.palette.blue['700'],
    color: theme.palette.common.white,
    padding: '6px 16px 6px 20px',
    whiteSpace: 'nowrap',
    zIndex: 10,
    display: 'inline-block',
    clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 100%, 10px 50%)', // Creates the transparent triangle on the left
  },
}))
