export const entityNamesByKey: Record<keyof typeof DataKeys, string> = {
  AddonVersions: 'Addon Versions',
  AlertRules: 'Alert Rules',
  Alerts: 'Alerts',
  AlertsTimeSeries: 'Alerts History',
  AllClusters: 'Clusters',
  ApiEndpoints: 'Available Api Endpoints',
  ApiGroups: 'Api Groups',
  ApiResources: 'Api Group Resources',
  AppDetails: 'App Details',
  Apps: 'Apps',
  AppsAvailableToCluster: 'Cluster Apps',
  CloudProviderDetails: 'Cloud Provider Details',
  CloudProviderRegionDetails: 'Cloud Provider Regions',
  CloudProviders: 'Cloud Providers',
  ClusterAddons: 'Cluster Addons',
  ClusterRoleBindings: 'Cluster Role Bindings',
  ClusterRoles: 'Cluster Roles',
  PrometheusInstances: 'Prometheus Instances',
  ServiceAccounts: 'Service Accounts',
  PrometheusRules: 'Prometheus Rules',
  PrometheusServiceMonitors: 'Prometheus Service Monitors',
  PrometheusAlertManagers: 'Prometheus Alert Managers',
  StorageClasses: 'Storage Classes',
  CSIDrivers: 'CSI Drivers',
  PersistentVolumes: 'Persistent Volumes',
  PersistentVolumeClaims: 'Persistent Volume Claims',
  Silences: 'Alert Silences',
  Ssh: 'SSH Keys',
  ServiceCatalog: 'Service Catalog',
  Clusters: 'Clusters',
  CoreApiResources: 'Api Resources',
  DeployedAppDetails: 'Deployed App Details',
  DeployedApps: 'Deployed Apps',
  Deployments: 'Deployments',
  Flavors: 'Flavors',
  ImportedClusters: 'Imported Clusters',
  Ingresses: 'Ingresses',
  KubeRoles: 'Kube Roles',
  KubeServiceDetails: 'Kube Service Details',
  KubeServices: 'Kube Services',
  ManagementCredentials: 'MFA Credentials',
  ManagementGroups: 'SSO Groups',
  ManagementGroupsMappings: 'SSO Group Mappings',
  ManagementRoles: 'Tenant Roles',
  ManagementTenants: 'Tenants',
  ManagementTenantsRoleAssignments: 'Tenant Role Assignments',
  ManagementUsers: 'Users',
  ManagementUsersRoleAssignments: 'User Role Assignments',
  Namespaces: 'Namespaces',
  Nodes: 'Nodes',
  K8sNodes: 'K8s Nodes',
  PodMetrics: 'Pod Metrics',
  Pods: 'Pods',
  RbacProfileBindings: 'RBAC Profile Bindings',
  RbacProfiles: 'RBAC Profiles',
  Regions: 'Regions',
  Repositories: 'Repositories',
  RepositoriesForCluster: 'Cluster Repositories',
  ResMgrHosts: 'Node Resources',
  RoleBindings: 'Role Bindings',
  SupportedRoleVersions: 'K8s Versions',
  EksVersions: 'EKS Versions',
  AwsRegionInfo: 'AWS Region Info',
  Tenants: 'Tenants',
  UserTenants: 'User Tenants',
  ConfigMaps: 'Config Maps',
  Users: 'Users',
  VirtualMachineDetails: 'Virtual Machine Details',
  VirtualMachines: 'Virtual Machines',
  VirtualMachineInstances: 'Virtual Machine Instances',
  VirtualMachinePresets: 'Virtual Machine Presets',
  Networks: 'Networks',
  DataVolumes: 'Data Volumes',
  Events: 'Events',
  CapiEvents: 'CAPI Events',
  PodLogs: 'Pod Logs',
  Cronjobs: 'Cron Jobs',
  Jobs: 'Jobs',
  StatefulSets: 'Stateful Sets',
  ReplicaSets: 'Replica Sets',
  DaemonSets: 'Daemon Sets',
  MonitoringAddons: 'Monitoring Addons',
  KubevirtAddons: 'Kubevirt Addons',
  NetworkingAddons: 'Networking Addons',
  ClusterAgents: 'Cluster Agents',
  CapiClusters: 'CAPI Clusters',
  CapiAwsClusters: 'CAPI AWS Clusters',
  NodeletControlPlanes: 'Nodelet Control Planes',
  AwsAmis: 'AWS AMIs',
  CapiHosts: 'CAPI Hosts',
  AwsClusterRoleIdentities: 'AWS Cluster Role Identities',
  AwsManagedControlPlanes: 'AWS Managed Control Planes',
  MachineDeployments: 'Machine Deployments',
  MachinePools: 'Machine Pools',
  AwsManagedMachinePools: 'AWS Managed Machine Pools',
  AwsMachineTemplates: 'Machine Templates',
  NodeletConfigs: 'Nodelet Configs',
  NodeletConfigTemplates: 'NodeletConfigTemplates',
  EksConfigs: 'EKS Configs',
  EksConfigTemplates: 'EksConfigTemplates',
  AwsMachinePools: 'AWS Machine Pools',
  AwsClusterStaticIdentities: 'AWS Cluster Static Identities',
  ClusterVersions: 'Cluster Versions',
  LiveMigrations: 'Live Migrations',
  ClusterUpgradeJobs: 'Cluster Upgrade Jobs',
  EksAmiTypes: 'EKS AMI Types',
  InstanceTypes: 'Instancetypes',
  ClusterInstanceTypes: 'Cluster Instancetypes',
  EksAddons: 'EksAddons',
  IpAllocations: 'IP Allocations',
  NetworkPlugins: 'Plugins Configurations',
  HostNetworkTemplates: 'Host Configurations',
  Secrets: 'Secrets',
  Images: 'Images',
  OpenstackVirtualMachines: 'VM Instances',
  AllOpenstackVirtualMachines: 'VM Instances',
  OpenstackNetworks: 'Networks',
  OpenstackSubnets: 'Subnets',
  OpenstackPorts: 'Ports',
  OpenstackRouters: 'Routers',
  OpenstackVolumes: 'Volumes',
  AllOpenstackVolumes: 'Volumes',
  OpenstackVolumeSnapshots: 'Volume Snapshots',
  NetworkIpAvailability: 'Network Availability',
  ResmgrHosts: 'Cluster Hosts',
  Hypervisors: 'Hypervisors',
  SshKeys: 'SSH Keys',
  FloatingIps: 'Public IPs',
  SecurityGroups: 'Security Groups',
  VolumeTypes: 'Volume Types',
  HostAggregates: 'Host Aggregates',
  Stacks: 'Stacks',
  AvailabilityZones: 'Availability Zones',
  ServerGroups: 'Server Groups',
  StoragePools: 'Storage Pools',

  // Kaapi
  KaapiClusters: 'Kaapi Clusters',
  KaapiOpenStackClusters: 'Kaapi OpenStack Clusters',
  KaapiHostedControlPlanes: 'Kaapi Control Planes',
  KaapiOpenStackMachineTemplates: 'Kaapi OpenStack Machine Templates',
  KubeadmConfigTemplates: 'Kubeadm Config Templates',
  KaapiMachineDeployments: 'Kaapi Machine Deployments',
  KaapiClusterAddons: 'Kaapi Cluster Add-ons',
  KaapiTenants: 'Kaapi Tenants',
  KaapiMachines: 'Kaapi Machines',
}

enum DataKeys {
  AddonVersions = 'AddonVersions',
  AlertRules = 'AlertRules',
  Alerts = 'Alerts',
  AlertsTimeSeries = 'AlertsTimeSeries',
  AllClusters = 'AllClusters',
  ApiEndpoints = 'ApiAccessEndpoints',
  ApiGroups = 'ApiGroups',
  ApiResources = 'ApiResources',
  AppDetails = 'AppDetails',
  Apps = 'Apps',
  AppsAvailableToCluster = 'AppsAvailableToCluster',
  CloudProviderDetails = 'CloudProviderDetails',
  CloudProviderRegionDetails = 'CloudProviderRegionDetails',
  CloudProviders = 'CloudProviders',
  ClusterAddons = 'ClusterAddons',
  ClusterRoleBindings = 'ClusterRoleBindings',
  ClusterRoles = 'ClusterRoles',
  PrometheusInstances = 'PrometheusInstances',
  ServiceAccounts = 'ServiceAccounts',
  PrometheusRules = 'PrometheusRules',
  PrometheusServiceMonitors = 'PrometheusServiceMonitors',
  PrometheusAlertManagers = 'PrometheusAlertManagers',
  StorageClasses = 'StorageClasses',
  CSIDrivers = 'CSIDrivers',
  PersistentVolumes = 'PersistentVolumes',
  PersistentVolumeClaims = 'PersistentVolumeClaims',
  Silences = 'Silences',
  Ssh = 'Ssh',
  ServiceCatalog = 'ServiceCatalog',
  Clusters = 'Clusters',
  CoreApiResources = 'CoreApiResources',
  DeployedAppDetails = 'DeployedAppDetails',
  DeployedApps = 'DeployedApps',
  Deployments = 'Deployments',
  Flavors = 'Flavors',
  ImportedClusters = 'ImportedClusters',
  Ingresses = 'Ingresses',
  KubeRoles = 'KubeRoles',
  KubeServiceDetails = 'KubeServiceDetails',
  KubeServices = 'KubeServices',
  ManagementCredentials = 'ManagementCredentials',
  ManagementGroups = 'ManagementGroups',
  ManagementGroupsMappings = 'ManagementGroupsMappings',
  ManagementRoles = 'ManagementRoles',
  ManagementTenants = 'ManagementTenants',
  ManagementTenantsRoleAssignments = 'ManagementTenantsRoleAssignments',
  ManagementUsers = 'ManagementUsers',
  ManagementUsersRoleAssignments = 'ManagementUsersRoleAssignments',
  Namespaces = 'Namespaces',
  Nodes = 'Nodes',
  PodMetrics = 'PodMetrics',
  Pods = 'Pods',
  RbacProfileBindings = 'RbacProfileBindings',
  RbacProfiles = 'RbacProfiles',
  Regions = 'Regions',
  Repositories = 'Repositories',
  RepositoriesForCluster = 'RepositoriesForCluster',
  ResMgrHosts = 'ResMgrHosts',
  RoleBindings = 'RoleBindings',
  SupportedRoleVersions = 'SupportedRoleVersions',
  EksVersions = 'EksVersions',
  AwsRegionInfo = 'AwsRegionInfo',
  Tenants = 'Tenants',
  UserTenants = 'UserTenants',
  ConfigMaps = 'ConfigMaps',
  Users = 'Users',
  VirtualMachineDetails = 'VirtualMachineDetails',
  VirtualMachines = 'VirtualMachines',
  VirtualMachineInstances = 'VirtualMachineInstances',
  VirtualMachinePresets = 'VirtualMachinePresets',
  Networks = 'Networks',
  DataVolumes = 'DataVolumes',
  Events = 'Events',
  CapiEvents = 'CapiEvents',
  PodLogs = 'PodLogs',
  MonitoringAddons = 'MonitoringAddons',
  KubevirtAddons = 'KubevirtAddons',
  NetworkingAddons = 'NetworkingAddons',
  K8sNodes = 'K8sNodes',
  StatefulSets = 'StatefulSets',
  ReplicaSets = 'ReplicaSets',
  Cronjobs = 'Cronjobs',
  Jobs = 'Jobs',
  DaemonSets = 'DaemonSets',
  ClusterAgents = 'ClusterAgents',
  CapiClusters = 'CapiClusters',
  CapiAwsClusters = 'CapiAwsClusters',
  NodeletControlPlanes = 'NodeletControlPlanes',
  AwsAmis = 'AwsAmis',
  AwsClusterRoleIdentities = 'AwsClusterRoleIdentities',
  AwsManagedControlPlanes = 'AwsManagedControlPlanes',
  CapiHosts = 'CapiHosts',
  MachineDeployments = 'MachineDeployments',
  AwsMachineTemplates = 'AwsMachineTemplates',
  NodeletConfigs = 'NodeletConfigs',
  NodeletConfigTemplates = 'NodeletConfigTemplates',
  EksConfigs = 'EksConfigs',
  EksConfigTemplates = 'EksConfigTemplates',
  MachinePools = 'MachinePools',
  AwsMachinePools = 'AwsMachinePools',
  AwsManagedMachinePools = 'AwsManagedMachinePools',
  AwsClusterStaticIdentities = 'AwsClusterStaticIdentities',
  ClusterVersions = 'ClusterVersions',
  LiveMigrations = 'LiveMigrations',
  ClusterUpgradeJobs = 'ClusterUpgradeJobs',
  EksAmiTypes = 'EksAmiTypes',
  InstanceTypes = 'InstanceTypes',
  ClusterInstanceTypes = 'ClusterInstanceTypes',
  EksAddons = 'EksAddons',
  IpAllocations = 'IpAllocations',
  NetworkPlugins = 'NetworkPlugins',
  HostNetworkTemplates = 'HostNetworkTemplates',
  Secrets = 'Secrets',
  Images = 'Images',
  OpenstackVirtualMachines = 'OpenstackVirtualMachines',
  AllOpenstackVirtualMachines = 'AllOpenstackVirtualMachines',
  OpenstackNetworks = 'OpenstackNetworks',
  OpenstackSubnets = 'OpenstackSubnets',
  OpenstackPorts = 'OpenstackPorts',
  OpenstackRouters = 'OpenstackRouters',
  OpenstackVolumes = 'OpenstackVolumes',
  AllOpenstackVolumes = 'AllOpenstackVolumes',
  OpenstackVolumeSnapshots = 'OpenstackVolumeSnapshots',
  NetworkIpAvailability = 'NetworkIpAvailability',
  ResmgrHosts = 'ResmgrHosts',
  Hypervisors = 'Hypervisors',
  SshKeys = 'SshKeys',
  FloatingIps = 'FloatingIps',
  SecurityGroups = 'SecurityGroups',
  VolumeTypes = 'VolumeTypes',
  HostAggregates = 'HostAggregates',
  Stacks = 'Stacks',
  AvailabilityZones = 'AvailabilityZones',
  ServerGroups = 'ServerGroups',
  StoragePools = 'StoragePools',

  // Kaapi
  KaapiClusters = 'KaapiClusters',
  KaapiOpenStackClusters = 'KaapiOpenStackClusters',
  KaapiHostedControlPlanes = 'KaapiHostedControlPlanes',
  KaapiOpenStackMachineTemplates = 'KaapiOpenStackMachineTemplates',
  KubeadmConfigTemplates = 'KubeadmConfigTemplates',
  KaapiMachineDeployments = 'KaapiMachineDeployments',
  KaapiClusterAddons = 'KaapiClusterAddons',
  KaapiTenants = 'KaapiTenants',
  KaapiMachines = 'KaapiMachines',
}

export default DataKeys

// TODO: Remove this when we move everything to TS, enums aren't supported in JS
export const ActionDataKeys = {
  AddonVersions: 'AddonVersions',
  AlertRules: 'AlertRules',
  Alerts: 'Alerts',
  AlertsTimeSeries: 'AlertsTimeSeries',
  AllClusters: 'AllClusters',
  ApiEndpoints: 'ApiAccessEndpoints',
  ApiGroups: 'ApiGroups',
  ApiResources: 'ApiResources',
  AppDetails: 'AppDetails',
  Apps: 'Apps',
  AppsAvailableToCluster: 'AppsAvailableToCluster',
  CloudProviderDetails: 'CloudProviderDetails',
  CloudProviderRegionDetails: 'CloudProviderRegionDetails',
  CloudProviders: 'CloudProviders',
  ClusterAddons: 'ClusterAddons',
  ClusterRoleBindings: 'ClusterRoleBindings',
  ClusterRoles: 'ClusterRoles',
  PrometheusInstances: 'PrometheusInstances',
  ServiceAccounts: 'ServiceAccounts',
  PrometheusRules: 'PrometheusRules',
  PrometheusServiceMonitors: 'PrometheusServiceMonitors',
  PrometheusAlertManagers: 'PrometheusAlertManagers',
  StorageClasses: 'StorageClasses',
  CSIDrivers: 'CSIDrivers',
  PersistentVolumes: 'PersistentVolumes',
  PersistentVolumeClaims: 'PersistentVolumeClaims',
  Silences: 'Silences',
  Ssh: 'Ssh',
  ServiceCatalog: 'ServiceCatalog',
  Clusters: 'Clusters',
  CoreApiResources: 'CoreApiResources',
  DeployedAppDetails: 'DeployedAppDetails',
  DeployedApps: 'DeployedApps',
  Deployments: 'Deployments',
  Flavors: 'Flavors',
  ImportedClusters: 'ImportedClusters',
  Ingresses: 'Ingresses',
  KubeRoles: 'KubeRoles',
  KubeServiceDetails: 'KubeServiceDetails',
  KubeServices: 'KubeServices',
  ManagementCredentials: 'ManagementCredentials',
  ManagementGroups: 'ManagementGroups',
  ManagementGroupsMappings: 'ManagementGroupsMappings',
  ManagementGroupsRoleAssignments: 'ManagementGroupsRoleAssignments',
  ManagementRoles: 'ManagementRoles',
  ManagementTenants: 'ManagementTenants',
  ManagementTenantsRoleAssignments: 'ManagementTenantsRoleAssignments',
  ManagementUsers: 'ManagementUsers',
  ManagementUsersRoleAssignments: 'ManagementUsersRoleAssignments',
  Namespaces: 'Namespaces',
  Nodes: 'Nodes',
  PodMetrics: 'PodMetrics',
  Pods: 'Pods',
  RbacProfileBindings: 'RbacProfileBindings',
  RbacProfiles: 'RbacProfiles',
  Regions: 'Regions',
  Repositories: 'Repositories',
  RepositoriesForCluster: 'RepositoriesForCluster',
  ResMgrHosts: 'ResMgrHosts',
  RoleBindings: 'RoleBindings',
  SupportedRoleVersions: 'SupportedRoleVersions',
  EksVersions: 'EKS Versions',
  AwsRegionInfo: 'AWS Region Info',
  Tenants: 'Tenants',
  UserTenants: 'UserTenants',
  ConfigMaps: 'ConfigMaps',
  Users: 'Users',
  VirtualMachineDetails: 'VirtualMachineDetails',
  VirtualMachines: 'VirtualMachines',
  VirtualMachineInstances: 'VirtualMachineInstances',
  VirtualMachinePresets: 'VirtualMachinePresets',
  Networks: 'Networks',
  DataVolumes: 'DataVolumes',
  Events: 'Events',
  CapiEvents: 'CapiEvents',
  PodLogs: 'PodLogs',
  MonitoringAddons: 'MonitoringAddons',
  KubevirtAddons: 'KubevirtAddons',
  NetworkingAddons: 'NetworkingAddons',
  K8sNodes: 'K8sNodes',
  StatefulSets: 'StatefulSets',
  ReplicaSets: 'ReplicaSets',
  Cronjobs: 'Cronjobs',
  Jobs: 'Jobs',
  DaemonSets: 'DaemonSets',
  CapiClusters: 'CapiClusters',
  CapiAwsClusters: 'CapiAwsClusters',
  NodeletControlPlanes: 'NodeletControlPlanes',
  AwsClusterRoleIdentities: 'AwsClusterRoleIdentities',
  AwsManagedControlPlanes: 'AwsManagedControlPlanes',
  CapiHosts: 'CapiHosts',
  MachineDeployments: 'MachineDeployments',
  MachinePools: 'MachinePools',
  AwsManagedMachinePools: 'AwsManagedMachinePools',
  AwsMachineTemplates: 'AwsMachineTemplates',
  NodeletConfigs: 'NodeletConfigs',
  NodeletConfigTemplates: 'NodeletConfigTemplates',
  EksConfigs: 'EksConfigs',
  EksConfigTemplates: 'EksConfigTemplates',
  AwsMachinePools: 'AwsMachinePools',
  AwsClusterStaticIdentities: 'AwsClusterStaticIdentities',
  LiveMigrations: 'LiveMigrations',
  ClusterUpgradeJobs: 'ClusterUpgradeJobs',
  EksAmiTypes: 'EksAmiTypes',
  InstanceTypes: 'InstanceTypes',
  ClusterInstanceTypes: 'ClusterInstanceTypes',
  EksAddons: 'EksAddons',
  IpAllocations: 'IpAllocations',
  NetworkPlugins: 'NetworkPlugins',
  HostNetworkTemplates: 'HostNetworkTemplates',
  Secrets: 'Secrets',
  Images: 'Images',
  OpenstackVirtualMachines: 'OpenstackVirtualMachines',
  AllOpenstackVirtualMachines: 'AllOpenstackVirtualMachines',
  OpenstackNetworks: 'OpenstackNetworks',
  OpenstackSubnets: 'OpenstackSubnets',
  OpenstackPorts: 'OpenstackPorts',
  OpenstackRouters: 'OpenstackRouters',
  OpenstackVolumes: 'OpenstackVolumes',
  AllOpenstackVolumes: 'AllOpenstackVolumes',
  OpenstackVolumeSnapshots: 'OpenstackVolumeSnapshots',
  NetworkIpAvailability: 'NetworkIpAvailability',
  ResmgrHosts: 'ResmgrHosts',
  Hypervisors: 'Hypervisors',
  SshKeys: 'SshKeys',
  FloatingIps: 'FloatingIps',
  SecurityGroups: 'SecurityGroups',
  VolumeTypes: 'VolumeTypes',
  HostAggregates: 'HostAggregates',
  Stacks: 'Stacks',
  AvailabilityZones: 'AvailabilityZones',
  ServerGroups: 'ServerGroups',
  StoragePools: 'StoragePools',

  // Kaapi
  KaapiClusters: 'KaapiClusters',
  KaapiOpenStackClusters: 'KaapiOpenStackClusters',
  KaapiHostedControlPlanes: 'KaapiHostedControlPlanes',
  KaapiOpenStackMachineTemplates: 'KaapiOpenStackMachineTemplates',
  KubeadmConfigTemplates: 'KubeadmConfigTemplates',
  KaapiMachineDeployments: 'KaapiMachineDeployments',
  KaapiClusterAddons: 'KaapiClusterAddons',
  KaapiTenants: 'KaapiTenants',
  KaapiMachines: 'KaapiMachines',
}
