// libs
import React from 'react'

import { makeStyles } from '@material-ui/styles'
import DocumentMeta from 'core/components/DocumentMeta'
import Theme from 'core/themes/model'
import useReactRouter from 'use-react-router'

import CapacityAndHealthPageHeader from './capacity-and-health-page-header'
import ListNodeGroups from './ListNodeGroups'
import ListWorkerNodes from './ListWorkerNodes'

const CapacityAndHealthDashboard = () => {
  const classes = useStyles({})
  const { history } = useReactRouter()

  return (
    <>
      <DocumentMeta title="Capacity And Health Dashboard" />

      <div className={classes.customGridContainer}>
        <CapacityAndHealthPageHeader />
        <ListNodeGroups />
        <ListWorkerNodes />
      </div>
    </>
  )
}

export default CapacityAndHealthDashboard

const useStyles = makeStyles((theme: Theme) => ({
  customGridContainer: {
    display: 'grid',
    gap: '16px',
  },
  headerText: {
    color: theme.components.card.passiveText,
  },
  headerSubText: {
    paddingTop: '24px',
  },
  divider: {
    border: '0',
    height: '1px',
    margin: '16px 0px',
    background: '#e6e6ea',
  },
}))
