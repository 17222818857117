import { AppSelector } from 'app/store'
import getDataSelector from 'core/utils/getDataSelector'
import { createSharedSelector } from 'core/utils/selectorHelpers'
import DataKeys from 'k8s/DataKeys'

export const kaapiMachinesSelector: AppSelector<any[]> = createSharedSelector(
  getDataSelector<DataKeys.KaapiMachines>(DataKeys.KaapiMachines),
  (kaapiMachines): any[] => {
    return kaapiMachines.map((kaapiMachine) => {
      return kaapiMachine
    })
  },
)
